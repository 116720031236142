import { REACT_APP_CDN } from 'utils/enviroment';
import IndexInvoiceDetailPresenter from './Index.Presenter';
import Skeleton from 'react-loading-skeleton';
import { DateTime } from 'luxon';
import { PaymentOptionEnum } from '../core/Modules/Invoice/Domain/Entities/InvoiceGet.entity';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import Lottie from 'lottie-react';
import PasswordAnimation from '../../../assets/lottie/error.json';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import CardForm from './CardForm/CardForm.Screen';
import { useTranslation } from 'react-i18next';

const IndexScreenInvoiceDetail = () => {
  const {
    query: { data, isError },
    id,
  } = IndexInvoiceDetailPresenter();
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [t] = useTranslation(['Invoice']);

  if (isError) {
    return (
      <div className="w-full bg-gray-400 min-h-screen">
        <div className="max-w-xs self-center m-auto">
          <h2 className="text-center pt-5 text-xl">{t('newInvoice.error')}</h2>
          <Lottie animationData={PasswordAnimation} />
          <h2 className="text-center pt-5 text-xl">
            {t('newInvoice.support')}({id})
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className=" bg-gray-400 min-h-screen">
      <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="bg-primary p-4">
          <Accordion collapsible type="single">
            <AccordionItem value="pay">
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex space-x-2">
                  <button
                    onClick={handlePrint}
                    className="px-3 py-1 border border-gray-300 text-white rounded-md flex items-center"
                  >
                    PDF
                  </button>
                  <AccordionTrigger>
                    <button className="px-3 py-1 bg-blue-500 border border-gray-300 text-white rounded-md flex items-center">
                      {t('newInvoice.payInvoice')}
                    </button>
                  </AccordionTrigger>
                </div>
              </div>

              <AccordionContent className="w-full animate-slideIn">
                <CardForm />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>

        <div ref={componentRef} className="p-6">
          <div className="flex justify-between mb-8">
            <div>
              <img
                className=" border-primary h-10"
                src={`${REACT_APP_CDN}/img/chambaLogo.svg`}
                alt={`${REACT_APP_CDN}/img/chambaLogo.svg`}
              />
            </div>
            <div className="text-sm text-gray-600">
              <p>Chamba Inc</p>
              <p>2785 W 104th PL </p>
              <p>Westminster CO 80234</p>
              <p>(303) 943-4469</p>
            </div>
          </div>

          {/* Invoice Details */}
          <div className="mb-8">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-semibold text-gray-600">{t('newInvoice.invoiceId')}</p>
                <p>{data?.consecutive ?? <Skeleton />}</p>
              </div>
              <div>
                <p className="text-sm font-semibold text-gray-600">{t('newInvoice.invoiceFor')}</p>
                <p>{data?.businessName ?? <Skeleton />}</p>
              </div>
              <div>
                <p className="text-sm font-semibold text-gray-600">{t('newInvoice.issueDate')}</p>
                <p>
                  {DateTime.fromISO(data?.invoiceDate ?? '').toLocaleString(
                    DateTime.DATE_SHORT
                  ) ?? <Skeleton />}
                </p>
              </div>
              <div>
                <p className="text-sm font-semibold text-gray-600">{t('newInvoice.invoiceName')}</p>
                <p>
                  {data?.paymentOption === PaymentOptionEnum.InvoicePerShift
                    ? data?.positionTitle !== null
                      ? data?.positionTitle
                      : ''
                    : data?.paymentOption === PaymentOptionEnum.InvoicePerDay
                      ? `${DateTime.fromISO(data?.startDate).toLocaleString(DateTime.DATE_MED)}`
                      : ` Week ${DateTime.fromISO(data?.startDate ?? '').toLocaleString(
                          DateTime.DATE_MED
                        )} - ${DateTime.fromISO(data?.endDate ?? '').toLocaleString(DateTime.DATE_MED)}`}
                </p>
              </div>
              <div>
                <p className="text-sm font-semibold text-gray-600">{t('newInvoice.totalPros')}</p>
                <p>{data?.totalPros}</p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            {data?.details.map((detail, key) => (
              <div key={key} className="overflow-x-auto">
                {detail.day && (
                  <p
                    style={{
                      paddingTop: 10,
                      fontSize: 11,
                      fontFamily: 'Helvetica-Bold',
                    }}
                  >
                    {DateTime.fromISO(detail.day).toLocaleString(DateTime.DATE_MED)}
                  </p>
                )}
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-100">
                      <th
                        style={{
                          width:
                            data.paymentOption === PaymentOptionEnum.InvoicePerShift ||
                            data.paymentOption == PaymentOptionEnum.InvoicePerWeek
                              ? '40%'
                              : '20%',
                        }}
                        className="text-left py-2 px-4"
                      >
                        {t('name')}
                      </th>
                      {(data.paymentOption === PaymentOptionEnum.InvoicePerDay ||
                        data.paymentOption == PaymentOptionEnum.InvoicePerWeek) && (
                        <>
                          <th>{t('newInvoice.position')}</th>
                          <th>Rate</th>
                        </>
                      )}
                      <th className="text-left py-2 px-4">{t('newInvoice.clockIn')}</th>
                      <th className="text-left py-2 px-4">{t('newInvoice.clockOut')}</th>
                      <th className="text-left py-2 px-4">{t('newInvoice.unpaid')}</th>
                      <th className="text-left py-2 px-4">{t('newInvoice.serviceFee')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail.applicants.map((applicant, keyApplicant) => (
                      <tr
                        key={keyApplicant}
                        className={keyApplicant % 2 == 0 ? 'bg-white' : 'bg-gray-200'}
                      >
                        <td
                          style={{
                            width:
                              data.paymentOption === PaymentOptionEnum.InvoicePerShift ||
                              data.paymentOption == PaymentOptionEnum.InvoicePerWeek
                                ? '40%'
                                : '20%',
                          }}
                          className="py-2 px-4"
                        >
                          {applicant.name}
                        </td>
                        {(data.paymentOption === PaymentOptionEnum.InvoicePerDay ||
                          data.paymentOption == PaymentOptionEnum.InvoicePerWeek) && (
                          <>
                            <td>{applicant.positionTitle}</td>
                            <td>{applicant.salaryHour || 0}</td>
                          </>
                        )}
                        <td className="text-left py-2 px-4">
                          {applicant.clockIn
                            ? DateTime.fromISO(applicant.clockIn).toLocaleString(
                                DateTime.TIME_SIMPLE
                              )
                            : 'No time'}
                        </td>
                        <td className="text-left py-2 px-4">
                          {applicant.clockOut
                            ? DateTime.fromISO(applicant.clockOut).toLocaleString(
                                DateTime.TIME_SIMPLE
                              )
                            : 'No time'}
                        </td>

                        <td className="text-left py-2 px-4">{applicant.breakTime ?? 0} mins</td>
                        <td className="text-left py-2 px-4">${applicant.laborCost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>

          <div className="w-full bg-gray-1200 opacity-50 h-[1px] mb-2" />

          {/* Total Amount */}
          <div className="text-right mb-8">
            {!data?.isMarkdown && (
              <div className="text-right text-xs font-medium">
                <span className="mr-1">Chamba {t('newInvoice.serviceFee')}</span>
                <span>${data?.serviceFee}</span>
              </div>
            )}
            <p className="text-xl font-bold">
              {t('newInvoice.amount')}: ${data?.totalAmount}
            </p>
          </div>

          {/* Payment Instructions */}
          <div className="bg-gray-100 p-4 rounded-md mb-8">
            <h3 className="font-bold mb-2">{t('newInvoice.note')}:</h3>
            <p className="text-sm">{t('newInvoice.description')}</p>
          </div>

          <div className="text-sm text-gray-600">
            <p>{t('newInvoice.paymentInformation')}:</p>
            <p>Routing 083009060</p>
            <p>2785 w 104th pl Westminster CO 80234</p>
            <p>Bank account 3915858367</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexScreenInvoiceDetail;
