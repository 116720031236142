import { PayInvoice } from '../Domain/Dtos/PayInvoice.dto';
import InvoiceRepository from '../Domain/Repository/Invoice.repository';

export default class InvoiceUseCases {
  private invoiceRepo: InvoiceRepository;

  constructor() {
    this.invoiceRepo = new InvoiceRepository();
  }

  public invokeGetInvoice = (id?: string) => {
    return this.invoiceRepo.getInvoice(id);
  };

  public invokePayInvoice = (data: PayInvoice) => {
    return this.invoiceRepo.payInvoice(data);
  };
}
