import ModalV2 from 'components/ModalV2';
import LocationPresenter from './CreateLocation.Presenter';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import SearchLocation from 'components/AutocompleteLocation';
import MapComponent from 'components/Map';
import { PARKING_INFO } from 'pages/postShift/shared/constants/parking';
import Icon from 'assets/icons';
import SelectV2 from 'components/SelectV2';

const CreateLocationScreen = () => {
  const presenter = LocationPresenter();
  const [t] = useTranslation(['Shifts']);
  const parking = PARKING_INFO.filter(
    (parking) => parking.id === presenter?.contextForm?.dataForm?.shiftInfo?.location?.parking
  );

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold my-3">{t('create.shiftInfo.location')}</p>
        {presenter.shouldNotShowSelect && (
          <p className="text-xs font-medium cursor-pointer" onClick={presenter.onClickClear}>
            Clear
          </p>
        )}
      </div>

      {!presenter.shouldNotShowSelect && (
        <SelectV2
          items={
            presenter?.locations?.data?.map((c) => ({
              id: c.id || c.location.address,
              text: c.location.address,
            })) ?? []
          }
          className="w-full rounded-lg text-base border-neutral-400 px-3 py-1 border mb-3 mt-2"
          itemSelected={presenter.selectedLocation}
          name={t('create.shiftInfo.selectLocation')}
          showDropDownArrow
          setSelectedItem={presenter.onClickLocation}
          firstElement={
            <p
              onClick={() => presenter.onClickCreateLocation()}
              className=" text-secondary-500 cursor-pointer ml-3 underline text-sm mt-3 mb-3"
            >
              {t('create.shiftInfo.createLocation')}
            </p>
          }
        />
      )}

      {!presenter.shouldNotShowSelect && (
        <p
          onClick={() => presenter.onClickCreateLocation()}
          className=" text-secondary-500 underline text-sm mt-3 mb-3"
        >
          {t('create.shiftInfo.createLocation')}
        </p>
      )}

      {presenter?.locationContext && (
        <div className="bg-neutral-200 border border-neutral-400 rounded p-4">
          <div className="flex justify-between">
            <p className="text-neutral-1600 text-base font-semibold ">
              {t('create.shiftInfo.location')}
            </p>
            <div className="mr-2  cursor-pointer" onClick={() => presenter.onClickEditLocation()}>
              <Icon icon="edit1" size={16} />
            </div>
          </div>

          <p className="text-neutral-1600 text-base ">
            {presenter.locationContext?.location.address}
          </p>
          <p className="text-neutral-1600 text-base font-semibold mt-2">
            {t('create.location.parking')}
          </p>
          <p className="text-neutral-1600 text-base ">{parking[0].text}</p>
        </div>
      )}

      <ModalV2
        isOpen={presenter.openedModal}
        setIsOpen={presenter.setOpenedModal}
        classNameContainer="w-full max-w-3xl"
      >
        <p className="font-bold text-primary text-2xl mb-3">{t('create.location.create')}</p>
        <form onSubmit={presenter.form.handleSubmit(presenter.onSubmit)}>
          <Controller
            control={presenter.form.control}
            name="location"
            render={({ field: { onChange } }) => (
              <>
                <SearchLocation
                  messageLocationAvailableShow
                  classNameInput=" font-regular text-sm sm:text-base appearance-none focus:outline outline-secondary-400 text-neutral-1600 placeholder:text-neutral-1600 rounded-lg border border-gray-300 bg-white relative focus:border-2 focus:border-secondary-400 focus:outline-secondary-400 focus:outline focus:outline-2 block w-full px-4 py-2 placeholder-primary   focus:z-20"
                  className="mt-2 font-medium text-sm sm:text-base appearance-none rounded-lg relative block w-full  border border-none bg-white  placeholder-gray-500 text-gray-900  focus:outline-secondary-400 focus:outline focus:outline-3 focus:border-2 focus:border-secondary-400 focus:z-10"
                  setValue={(data) => onChange(data)}
                  placeholder={t('create.location.location')}
                  defaultValue={presenter.locationContext?.location?.address}
                />
              </>
            )}
          />
          <div className="mt-4 rounded-xl overflow-hidden">
            <MapComponent
              whenReady={true}
              latitude={presenter.locationMapData.latitude}
              longitude={presenter.locationMapData.longitude}
            />
          </div>
          {presenter.form.formState.errors.location && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <p className="font-bold text-primary text-xl mt-4">{t('create.location.parking')}</p>

          <Controller
            control={presenter.form.control}
            name="parking"
            render={({ field: { onChange, value } }) => (
              <div className="flex my-2 mt-4 items-center">
                {PARKING_INFO.map((e) => (
                  <>
                    <input
                      type="radio"
                      className="w-4 h-4"
                      name="paid"
                      checked={value === e.id}
                      onChange={() => onChange(e.id)}
                    />
                    <label className="ml-1 mr-4 text-neutralBlue">{e.text}</label>
                  </>
                ))}
              </div>
            )}
          />

          {presenter.form.formState.errors.parking && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <TextArea
            {...presenter.form.register('info')}
            rows={5}
            className="mt-3 w-full"
            placeholder={t('create.location.additional')}
          />
          {presenter.form.formState.errors.info && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <TextArea
            {...presenter.form.register('clockInInfo')}
            rows={5}
            className="mt-3 w-full"
            placeholder={t('create.location.instructions')}
          />
          {presenter.form.formState.errors.clockInInfo && (
            <p className="text-xs text-warnings-500 mt-2">{t('create.required')}</p>
          )}

          <div className="flex justify-between flex-shrink-0 mt-7">
            <Button
              size="xs"
              type="button"
              onClick={presenter.onCancel}
              name={t('create.contacts.cancel')}
              color="outline"
            />
            <Button type="submit" size="xs" name={t('create.contacts.save')} color="primary" />
          </div>
        </form>
      </ModalV2>
    </>
  );
};

export default CreateLocationScreen;
