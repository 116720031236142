export default {
  en: {
    paid: 'Paid',
    pending: 'Pending',
    due: 'Past due',
    export: 'Export',
    noElement: 'There are no elements',
    title: 'Weekly invoice (net ',
    days: ' days)',
    invoiceNumber: 'Invoice Number',
    time: 'Time period',
    issue: 'Issue date',
    dueDate: 'Due date',
    bill: 'Total bill',
    status: 'Status',
    account: 'Bank account',
    bankInfo: 'See bank info',
    routingNumber: 'Routing number',
    address: 'Address',
    companyName: 'Company name',
    phone: 'Phone',
    rate: 'Rate',
    clockIn: 'Clock in',
    clockOut: 'Clock out',
    unPaidHours: 'Unpaid hours',
    laborCost: 'Labor cost',
    chambaFee: 'Chamba fee',
    total: 'Total',
    noTransactions: 'You have not made any transaction yet',
    serviceFee: 'Service fee',
    startDate: 'Start date',
    endDate: 'End Date',
    name: 'Name',
    position: 'Position',
    chambaServiceFee: 'Chamba service fee',
    totalCost: 'Total cost',
    newInvoice: {
      payInvoice: 'Pay invoice',
      error: 'Something went wrong',
      support: 'Please contact support ID',
      invoiceId: 'Invoice ID',
      invoiceFor: 'Invoice For',
      issueDate: 'Issue date',
      invoiceDate: 'Invoice date',
      invoiceName: 'Invoice name',
      totalPros: 'Total pros',
      position: 'Position',
      clockIn: 'Clock-In',
      clockOut: 'Clock-Out',
      unpaid: 'Unpaid  hours',
      laborCost: 'Labor cost',
      serviceFee: 'service fee',
      amount: 'Amount',
      note: 'Note',
      description:
        'In accordance with our terms and conditions, a 1.5% late fee will accrue on the 1st business day of each calendar month during which your payment for this invoice remains past due. To avoid this financing fee, please review the details below and coordinate payment at your earliest convenience.',
      paymentInfo: 'Payment information',
      routing: 'Routing',
      bankAccount: 'Bank account',
      paymentInformation: 'Payment information',
    },
  },
  es: {
    paid: 'Pagado',
    pending: 'Pendiente',
    due: 'Retrasado',
    export: 'Exportar',
    noElement: 'No hay elementos',
    title: 'Factura semanal (neto ',
    days: ' días)',
    invoiceNumber: 'Número factura',
    time: 'Periodo',
    issue: 'Fecha de expedición',
    dueDate: 'Fecha de vencimiento',
    bill: 'Cuenta total',
    bankInfo: 'Información del banco',
    status: 'Estado',
    account: 'Cuenta del banco',
    routingNumber: 'Número de enrutamiento',
    address: 'Dirección',
    phone: 'Teléfono',
    rate: 'Tarifa',
    clockIn: 'Entrada',
    clockOut: 'Salida',
    unPaidHours: 'Horas no pagadas',
    laborCost: 'Costo de mano de obra',
    chambaFee: 'Tarifa de Chamba',
    total: 'Total',
    noTransactions: 'Aún no has realizado ninguna transacción',
    serviceFee: 'Tarifa de servicio',
    name: 'Nombre',
    position: 'Posición',
    chambaServiceFee: 'Tarifa de servicio de Chamba',
    totalCost: 'Costo total',
    newInvoice: {
      payInvoice: 'Pagar factura',
      error: 'Algo salió mal',
      support: 'Por favor contacte con el ID de soporte',
      invoiceId: 'ID de factura',
      invoiceFor: 'Factura por',
      issueDate: 'Fecha de asunto',
      invoiceDate: 'fecha de factura',
      invoiceName: 'Nombre de la factura',
      totalPros: 'Ventajas totales',
      position: 'Posición',
      clockIn: 'Entrada',
      clockOut: 'Reloj de salida',
      unpaid: 'Horas no remuneradas',
      laborCost: 'Costo laboral',
      serviceFee: 'tarifa de servicio',
      amount: 'Cantidad',
      note: 'Nota',
      description:
        'De acuerdo con nuestros términos y condiciones, se acumulará un cargo por pago atrasado del 1,5% el primer día hábil de cada mes calendario durante el cual el pago de esta factura permanezca vencido. ',
      paymentInfo: 'Información de pago',
      routing: 'Enrutamiento',
      bankAccount: 'Cuenta bancaria',
      paymentInformation: 'Información de pago',
    },
  },
};
