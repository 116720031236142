import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  TranslateEmailConfirmation,
  TranslateLoginExceptions,
} from 'utils/cognito/translateErrors';
import { API_ENDPOINTS, getEndpoint } from './api.endpoints';
import api from './http';
import { AuthMethod, ShowToastError } from 'utils';
import store from '../store/index';
import { clearSlice, setCompanyPartner, setEmailRecovery, setPassword } from 'store/business';
import historyObject from 'routes/history';
import { RoutesConstant, USER_ID } from 'utils/constants';
import { ICompany } from './business/model';

export interface ICognitoUser {
  id: string;
  name: string;
  email: string;
  authMethod: string;
  cognitoId: string;
  businessId: string;
  role: number;
  position: string;
  creationDate: Date;
  isActive: boolean;
  notifications: {
    email: boolean;
    sms: boolean;
  };
}

export interface ILoginUser {
  email: string;
  password: string;
}

export interface IRegisterUser {
  email: string;
  confirmEmail: string;
  password: string;
  name: string;
  repeat_password: string;
}

export interface IRegisterInvitedUser {
  name: string;
  email: string;
  cognitoId?: string;
  businessId: string;
  role: number;
  position: string;
  password: string;
}

export interface IRegisterRequest {
  name: string;
  email: string;
  cognitoId: string;
}

export interface IChangePassword {
  oldPass: string;
  newPass: string;
  confirmPass: string;
}

export interface IForgotStep2 {
  code: string;
  password: string;
  confirmPass: string;
}

export interface IFederatedRequest {
  name: string;
  email: string;
  authMethod: number;
  cognitoId: string;
}

export interface IInviteUserRequest {
  name: string;
  businessId: string;
  email: string;
  role: number;
  userBusiness: string;
}

export const LoginUser = async (user: ILoginUser): Promise<CognitoUserSession | undefined> => {
  try {
    await Auth.signIn(user.email.toLocaleLowerCase(), user.password);
    const instance = await Auth.currentSession();
    return instance;
  } catch (e) {
    const err = e as IError;
    if (err.code === 'UserNotConfirmedException') {
      store.dispatch(setEmailRecovery(user.email));
      store.dispatch(setPassword(user.password));
      historyObject.replace(RoutesConstant.unsecure.confirmEmail);
    } else if (
      err.code === 'FORCE_CHANGE_PASSWORD' ||
      err.code === 'PasswordResetRequiredException'
    ) {
      historyObject.replace(RoutesConstant.unsecure.forcePassword + '/' + user.email);
    } else {
      throw new Error(TranslateLoginExceptions(err.code ? err.code : ''));
    }
  }
};

export const ResendConfirmationCode = async (email: string) => {
  try {
    await Auth.resendSignUp(email);
  } catch (e) {
    const err = e as IError;
    throw new Error(TranslateEmailConfirmation(err.code ? err.code : ''));
  }
};

export const ConfirmCode = async (email: string, code: string) => {
  try {
    await Auth.confirmSignUp(email, code);
  } catch (e) {
    const err = e as IError;
    throw new Error(TranslateEmailConfirmation(err.code ? err.code : ''));
  }
};

export const RegisterUser = async (
  user: IRegisterUser,
  partner: string | null
): Promise<{ confirmed: boolean }> => {
  try {
    const result = await Auth.signUp({
      username: user.email.toLocaleLowerCase(),
      password: user.password,
      attributes: {
        email: user.email.toLocaleLowerCase(),
      },
    });

    const data: IRegisterRequest = {
      cognitoId: result.userSub,
      email: user.email,
      name: user.name,
    };

    await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.SIGNUP_EMAIL), data);

    if (!result.userConfirmed) {
      store.dispatch(setEmailRecovery(user.email));
      store.dispatch(setPassword(user.password));

      if (partner) store.dispatch(setCompanyPartner(partner));

      historyObject.replace(RoutesConstant.unsecure.confirmEmail, { partner });
      return { confirmed: true };
    } else {
      return {
        confirmed: false,
      };
    }
  } catch (e) {
    const err = e as IError;
    throw new Error(
      err.message
        ? err.message
        : 'There was an error trying to create your account, please try again.'
    );
  }
};

export const RegisterOldUser = async (data: IRegisterRequest): Promise<void> => {
  try {
    await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.SIGNUP_EMAIL), data);
    store.dispatch(setEmailRecovery(data.email));
    historyObject.replace(RoutesConstant.secure.onBoarding);
  } catch (e) {
    const err = e as IError;
    throw new Error(
      err.message
        ? err.message
        : 'There was an error trying to create your account, please try again.'
    );
  }
};

export const RegisterInvtedUser = async (user: IRegisterInvitedUser): Promise<void> => {
  try {
    const result = await Auth.signUp({
      username: user.email.toLocaleLowerCase(),
      password: user.password,
      attributes: {
        email: user.email.toLocaleLowerCase(),
      },
    });

    const data: Omit<IRegisterInvitedUser, 'password'> = {
      cognitoId: result.userSub,
      email: user.email,
      name: user.name,
      businessId: user.businessId,
      position: user.position,
      role: user.role,
    };

    await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.SIGNUP_INVITED_BUSINESS), data);
  } catch (e) {
    const err = e as IError;
    throw new Error(
      err.message
        ? err.message
        : 'There was an error trying to create your account, please try again.'
    );
  }
};

export const SignFederatedUser = async (data: ILoginUser): Promise<ICompany> => {
  try {
    const result = await api.post<ICompany>(
      getEndpoint(API_ENDPOINTS.BUSINESS.SIGN_FEDERATED_BUSINESS),
      data
    );
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const GetBusiness = async (id: string): Promise<ICompany> => {
  try {
    const result = await api.get<ICompany>(
      getEndpoint(API_ENDPOINTS.BUSINESS.GET_BUSINESS, { id })
    );
    return result.data;
  } catch (e) {
    ShowToastError(e);
    throw e;
  }
};

export const GetBusinessCognito = async (id: string): Promise<ICognitoUser> => {
  const result = await api.get<ICognitoUser>(
    getEndpoint(API_ENDPOINTS.BUSINESS.GET_BUSINESS_COGNITO, { id })
  );
  localStorage.setItem(USER_ID, result.data.id);
  return result.data;
};

export const CheckCognitoInstance = async (): Promise<CognitoUserSession | null> => {
  try {
    const data = await Auth.currentSession();
    return data;
  } catch {
    return null;
  }
};

export const CheckLoginMethod = (result: CognitoUserSession): AuthMethod => {
  const idToken = result.getIdToken();
  const identities = idToken.payload?.identities;

  let authMethod: AuthMethod = AuthMethod.Email;

  if (identities && identities.length > 0) {
    const type = identities[0].providerName;

    switch (type) {
      case 'Google':
        authMethod = AuthMethod.Google;
        break;
      case 'Facebook':
        authMethod = AuthMethod.Facebook;
        break;
      case 'SignInWithApple':
        authMethod = AuthMethod.Apple;
        break;

      default:
        authMethod = AuthMethod.Email;
    }
  }

  return authMethod;
};

export const ChangePassword = async (data: CognitoUserSession, password: IChangePassword) => {
  try {
    await Auth.changePassword(data, password.oldPass, password.newPass);
  } catch (e) {
    const err = e as IError;
    throw new Error(TranslateLoginExceptions(err.code ? err.code : ''));
  }
};

export const ActionResetPassword = async (data: IForgotStep2, email: string) => {
  await Auth.forgotPasswordSubmit(email, data.code, data.password);
};

export const ResetPassword = async (email: string) => {
  try {
    await Auth.forgotPassword(email);
  } catch (e) {
    const err = e as IError;
    throw new Error(TranslateLoginExceptions(err.code ? err.code : ''));
  }
};

export const RegisterCognito = async (data: IFederatedRequest): Promise<ICognitoUser> => {
  const result = await api.post<ICognitoUser>(
    getEndpoint(API_ENDPOINTS.BUSINESS.REGISTER_COGNITO),
    data
  );
  return result.data;
};

export const InviteBusinessUser = async (data: IInviteUserRequest) => {
  await api.post(getEndpoint(API_ENDPOINTS.BUSINESS.INVITE_BUSINESS), data);
};

export const CloseSession = async (): Promise<void> => {
  try {
    sessionStorage.clear();
    await Auth.signOut();
    store.dispatch(clearSlice());
  } catch {
    //
  }
};
