export enum PaymentOptionEnum {
  NotSelected = 0,
  AutoCharge = 1,
  InvoicePerShift = 2,
  InvoicePerDay = 3,
  InvoicePerWeek = 4,
}

export interface InvoiceGet {
  invoiceId: string;
  consecutive: number;
  paymentOption: PaymentOptionEnum;
  invoiceDate: string;
  businessName: string;
  startDate: string;
  endDate: string;
  positionTitle: string;
  totalPros: number;
  serviceFee: number;
  totalHours: number;
  totalAmount: number;
  isMarkdown: boolean;
  details: [
    {
      day: string;
      applicants: [
        {
          name: string;
          positionTitle: string;
          isTotalCompensation: true;
          salaryTotal: number;
          salaryHour: number;
          clockIn: string;
          clockOut: string;
          breakTime: number;
          laborCost: number;
        },
      ];
    },
  ];
}
