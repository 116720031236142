import InvoiceUseCases from 'pages/invoiceDetail/core/Modules/Invoice/Application/InvoiceUseCase';
import { parseException } from 'utils/errors/handleAxiosError';

const invoiceUseCase = new InvoiceUseCases();

const CardFormAction = () => {
  const executePayInvoice = async (invoiceId: string, paymentMethodId: string) => {
    try {
      await invoiceUseCase.invokePayInvoice({
        invoiceId,
        paymentMethodId,
      });
    } catch (e) {
      parseException(e);
    }
  };

  return { executePayInvoice };
};

export default CardFormAction;
