import { useTranslation } from 'react-i18next';
import { passwordRegex } from 'utils/constants';
import * as yup from 'yup';

const SetupPasswordForm = () => {
  const [t] = useTranslation(['Forgot']);

  const schema = yup.object({
    code: yup.string().required().length(6, t('passwordRequired')),
    password: yup
      .string()
      .required(t('passwordRequired'))
      .matches(passwordRegex, t('passwordRequirements')),
    passwordConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], t('noMatch')),
  });
  return { schema };
};

export default SetupPasswordForm;
