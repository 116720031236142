import * as React from 'react';
import { useTranslation } from 'react-i18next';
import StarRating from 'components/StarsRating';
import ImageAvatar from 'components/ImageAvatar';
import { cn } from 'utils';
import { HireStatusEnum } from '../core/Modules/Hire/Domain/Dtos/HireList.dto';
import SheetProfile from 'components/SheetProfile';
import IndexScreenDescription from '../screens/Description/Index.Screen';
import { DateTime } from 'luxon';
export interface ProfileCardProps {
  key: string;
  name: string;
  totalShifts?: number;
  userImage: string;
  userId: string;
  preferredUserId: string;
  isFavorite?: boolean;
  rating?: number;
  ratesNumber: number;
  isBlocked?: boolean;
  showProfile?: boolean;
  onRefresh: () => void;
  menuItems: JSX.Element;
  className?: string;
  status: HireStatusEnum;
  statusDate: string | null;
  hireId: string;
}

const ProfileCard = ({
  name,
  status,
  userImage,
  showProfile = true,
  ratesNumber,
  rating,
  userId,
  statusDate,
  className,
  hireId,
}: ProfileCardProps): JSX.Element => {
  const [t] = useTranslation('ApplicantsProfile');
  const [openModal, setOpenModal] = React.useState<string>();
  const [openHire, setOpenHire] = React.useState<{ hireId: string; name: string }>();
  const [hireTranslation] = useTranslation(['Hire']);

  const getStatus = React.useMemo(() => {
    if (status === HireStatusEnum.Accepted) return t('profile.hiredSince');

    if (status === HireStatusEnum.Declined) return t('profile.declined');

    return t('profile.offerSent');
  }, [status]);

  return (
    <div
      className={cn(
        'flex w-full flex-col bg-white p-5 rounded-2xl border border-[#D7D7D7] my-4',
        className
      )}
    >
      <div className="w-full flex">
        <div className="flex w-11/12">
          <div className="mr-5">
            <ImageAvatar url={userImage} size={48} />
          </div>
          <div>
            <div>
              <div className="flex">
                <h3 className="font-bold text-base">{name}</h3>
                {status === HireStatusEnum.Accepted && (
                  <h3 className="text-secondary-400 font-semibold ml-2">{t('profile.hired')}</h3>
                )}
                {status === HireStatusEnum.Declined && (
                  <h3 className="text-warnings-500 font-semibold ml-2">{t('profile.declined')}</h3>
                )}
              </div>

              {statusDate && (
                <p className="text-xs text-grayText">
                  {getStatus}: {DateTime.fromISO(statusDate).toLocaleString(DateTime.DATE_SHORT)}
                </p>
              )}

              <div className=" flex items-center">
                <p className="text-xs text-grayText mr-2">
                  {t('profile.rating')} {rating}
                </p>
                <div className="text-secondary-500">
                  <StarRating defaultRating={rating} rates={ratesNumber} size={10} disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
                <div className="w-1/12 flex justify-end">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <Icon icon={'dots'} size={15} className="cursor-pointer" />
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">{menuItems}</div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div> */}
      </div>

      {showProfile && (
        <div className="w-full mt-2 flex justify-end items-center">
          <div
            className="text-secondary-500 mr-3 text-sm cursor-pointer"
            onClick={() => setOpenHire({ hireId, name })}
          >
            {hireTranslation('offer')}
          </div>
          <div
            className="flex cursor-pointer bg-secondary-500 rounded px-5"
            onClick={() => setOpenModal(userId)}
          >
            <p className="text-sm text-white p-1">{hireTranslation('profile')}</p>
          </div>
        </div>
      )}

      <SheetProfile
        actionButtons={<></>}
        idProfile={userId}
        selected={openModal !== undefined}
        setSelected={setOpenModal}
      />

      {openHire && (
        <IndexScreenDescription
          open={openHire !== undefined}
          selected={openHire}
          setSelected={setOpenHire}
        />
      )}
    </div>
  );
};

export default ProfileCard;
