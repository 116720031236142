import { useQuery } from '@tanstack/react-query';
import IndexInvoiceDetailAction from './Index.Action';
import { useParams } from 'react-router-dom';

const IndexInvoiceDetailPresenter = () => {
  const action = IndexInvoiceDetailAction();
  const { id } = useParams();

  const query = useQuery(['user', id], () => action.executeGetInvoice(id), {
    enabled: !!id,
  });

  return { query, id };
};

export default IndexInvoiceDetailPresenter;
