import Container from 'components/Container';
import Menu from 'pages/timesheet/shared/components/Menu';
import PendingItem from 'pages/timesheet/shared/components/PendingItem';
import MiniCalendar from 'pages/timesheet/shared/components/Calendar';
import Help from 'pages/timesheet/shared/components/Help';
import SelectV2 from 'components/SelectV2';
import Button from 'components/Button';
import TableTimeSheet from 'pages/timesheet/shared/components/Table';
import Skeleton from 'react-loading-skeleton';
import { TimeSheetPresenter } from './IndexPage.Presenter';
import { useTranslation } from 'react-i18next';
import InputFilter from 'pages/timesheet/shared/components/filter-input';
import Lottie from 'lottie-react';
import NotFound from 'assets/lottie/data-notfound.json';

const TimesheetScreen = () => {
  const presenter = TimeSheetPresenter();
  const [t] = useTranslation(['Timesheet']);

  return (
    <Container className="bg-white">
      <div>
        <h1 className="font-bold text-primary text-2xl sm:text-4xl pb-4">{t('timesheet')}</h1>
        <div className="flex items-center">
          <Menu
            onClickOpenCalendar={presenter.onClickOpenCalendar}
            onClickOutsideCalendar={presenter.onClickOutsideCalendar}
            setStartDate={presenter.setStartDate}
            startDate={presenter.startDate}
            openCalendar={presenter.openCalendar}
            onAddDays={presenter.onAddDays}
            onDecrementDays={presenter.onDecrementDays}
            sheetData={presenter.sheetData}
            setDate={presenter.setDate}
          />
          <PendingItem />
          <div className="flex ml-6 mr-4">
            {!presenter.sheetData ? (
              <Skeleton />
            ) : (
              presenter.sheetData.timesheetWeekItems.map((e, key) => (
                <MiniCalendar
                  key={key}
                  selected={presenter.selectedDate}
                  endDate={e.dayEnd}
                  startDate={e.dayStart}
                  timeSheetStatus={e.timesheetStatus}
                  onClick={() =>
                    presenter.setSelectedDate({
                      endDate: e.dayEnd,
                      startDate: e.dayStart,
                      allowChanges: e.allowChanges,
                    })
                  }
                />
              ))
            )}
          </div>

          <div>
            <Help />
          </div>
        </div>

        <div className="mt-5 w-full flex items-center">
          <div className="mr-5">
            <InputFilter
              onChange={(e) => presenter.setSearchText(e.target.value)}
              name="name"
              placeholder="Search by Pro Name"
              type="text"
              className="max-w-xl py-1"
            />
          </div>
          <div className="mr-4">
            <SelectV2
              className="border-[1px] max-w-xs rounded-xl px-3 py-0"
              name="Sort Name: A - Z"
              itemSelected={presenter.sortNameAsc ?? { id: '1', text: '' }}
              items={[
                {
                  id: '1',
                  text: 'Sort Name A - Z',
                },
                {
                  id: '2',
                  text: 'Sort Name Z - A',
                },
              ]}
              setSelectedItem={(e) => {
                presenter.setSortNameAsc(e);
              }}
            />
          </div>
          <div>
            <Button
              disabled={presenter.selectedExecutions.length === 0}
              name={t('sheetTip.approveSelected')}
              onClick={presenter.onApproveSelected}
              size="sm"
            />
          </div>
        </div>

        <TableTimeSheet
          selectedDate={presenter.selectedDate}
          data={presenter.timeShiftSheetList}
          setPage={presenter.setPage}
          createIndividualRate={presenter.createIndividualRate}
          setTipValue={presenter.setTipValue}
          tipValue={presenter.tipValue}
          updateTip={presenter.updateTip}
          onClickSelectAllExecutions={presenter.onClickSelectAllExecutions}
          selectedExecutions={presenter.selectedExecutions}
          onClickExecution={presenter.onClickExecution}
          onApprove={presenter.onApprove}
          onPreferredUser={presenter.onCreatePreferredUser}
          onBlockUser={presenter.onBlockUser}
          refetch={presenter.refetch}
        />

        {presenter.timeShiftSheetList?.resultListShift.timesheetItems.length === 0 && (
          <div className="flex justify-center">
            <div style={{ width: 350 }}>
              <Lottie
                rendererSettings={{
                  preserveAspectRatio: 'xMidYMid slice',
                }}
                className="m-auto"
                animationData={NotFound}
                loop={true}
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default TimesheetScreen;
