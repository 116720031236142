import { IOnBoardingApiRequest } from 'actions/business/model';
import Input from 'components/InputPost';
import SelectPost from 'components/SelectPost';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { GetOnboardDataThunk } from 'store/business/api.thunks';
import { getCognitoUser, getCompanyReferred, getOnboardingData } from 'store/business/selectors';
import { emailRegex, TERMS } from 'utils/constants';
import { CloseSession } from 'actions/auth.api';
import SelectCountry, { countries } from 'components/SelectCountry';
import { getCompanyCategory, getCompanyLabel } from 'utils/partners';

export interface OnboardingForm {
  email: string;
  phoneNumber: string;
  companyName: string;
  companyCategory: IItems;
  whereDidYouHear: IItems;
  country: IItems;
}

export interface IForm {
  setData: (data: IOnBoardingApiRequest) => void;
  nextStep: () => void;
}

const Form = ({ setData, nextStep }: IForm): JSX.Element => {
  const [t] = useTranslation(['OnBoarding']);
  const dispatch = useAppDispatch();
  const business = useSelector(getOnboardingData);
  const cognitoUser = useSelector(getCognitoUser);
  const companyReferred = useSelector(getCompanyReferred);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<OnboardingForm>({
    defaultValues: {
      email: '',
      phoneNumber: '',
      companyName: companyReferred ? getCompanyLabel(companyReferred) : '',
      companyCategory: companyReferred
        ? getCompanyCategory(companyReferred)
        : { id: '0', text: t<string>('form.companyCategory') },
      whereDidYouHear: { id: '0', text: t<string>('form.hearAboutUs') },
    },
  });

  const submitForm = async (data: OnboardingForm) => {
    const country = countries.find((v) => v.code === data.country.id)?.id ?? countries[0].id;
    if (cognitoUser && cognitoUser.id) {
      const onboardingArgs: IOnBoardingApiRequest = {
        email: data.email,
        phoneNumber: data.phoneNumber,
        name: data.companyName,
        type: data.companyCategory.id,
        discoveryOption: data.whereDidYouHear.id,
        businessUserId: cognitoUser.id,
        taxType: 1,
        country: parseInt(country),
      };

      setData(onboardingArgs);
      nextStep();
    }
  };

  React.useEffect(() => {
    dispatch(GetOnboardDataThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (cognitoUser) {
      reset({
        email: cognitoUser.email,
      });
    }
  }, [cognitoUser, reset]);

  return (
    <div className="w-full">
      <h2 className="text-3xl text-primary font-bold mb-8">{t('signUp')}</h2>
      <form onSubmit={handleSubmit(submitForm)} className="w-full">
        <Input
          customStyle="mt-4 w-full font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
          label={'Email'}
          name={`email`}
          placeholder={t<string>('form.email')}
          register={register}
          type="text"
          errors={errors.email}
          validation={{
            required: true,
            pattern: emailRegex,
          }}
        />
        <Input
          customStyle="mt-5 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
          label={'phoneNumber'}
          name={`phoneNumber`}
          placeholder={t<string>('form.phoneNumber')}
          register={register}
          type="number"
          errors={errors.phoneNumber}
          validation={{
            required: true,
          }}
        />
        <Input
          customStyle="mt-5 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
          label={'Company Name'}
          name={`companyName`}
          placeholder={t<string>('form.companyName')}
          register={register}
          type="text"
          errors={errors.companyName}
          validation={{
            required: true,
          }}
        />
        <Controller
          control={control}
          name="companyCategory"
          rules={{
            required: true,
            validate: (value: IItems) => value.id !== '0' || t<string>('required'),
          }}
          render={({ field: { onChange, value } }) => (
            <SelectPost
              customStyle="mt-5 flex  text-left bg-white font-medium border-2 border-[#EDEDED] hover:border-2 hover:border-secondary-500 placeholder-primary text-gray-900 text-sm sm:text-base items-center appearance-none rounded-2xl relative w-full px-4 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              items={business?.businessTypes ? business.businessTypes : []}
              itemSelected={value}
              setSelectedItem={(type) => {
                onChange(type);
              }}
              errors={errors.companyCategory}
              register={register}
              disabled={!!companyReferred}
              validation={{
                required: true,
                validate: (value: IItems) => value.id !== '0' || t<string>('required'),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="whereDidYouHear"
          rules={{
            required: true,
            validate: (value: IItems) => value.id !== '0' || t<string>('required'),
          }}
          render={({ field: { onChange, value } }) => (
            <SelectPost
              customStyle="mt-5 flex  text-left bg-white font-medium border-2 border-[#EDEDED] hover:border-2 hover:border-secondary-500 placeholder-primary text-gray-900 text-sm sm:text-base items-center appearance-none rounded-2xl relative w-full px-4 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              items={business?.discoveryOptions ? business.discoveryOptions : []}
              itemSelected={value}
              setSelectedItem={(type) => {
                onChange(type);
              }}
              errors={errors.companyCategory}
              register={register}
              validation={{
                required: true,
                validate: (value: IItems) => value.id !== '0' || t<string>('required'),
              }}
            />
          )}
        />

        <div className="mt-4">
          <Controller
            control={control}
            name="country"
            rules={{
              required: true,
              validate: (value: IItems) => value.id !== '0' || t<string>('required'),
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <SelectCountry
                  selectedControlled={value}
                  setSelectedControlled={onChange}
                  className="flex justify-start text-left bg-white font-medium border-2 border-[#EDEDED] hover:border-2 hover:border-secondary-500 placeholder-primary text-gray-900 text-sm sm:text-base items-center appearance-none rounded-2xl relative w-full px-4 py-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                />
              </>
            )}
          />
        </div>
        {errors.country && <small className="text-warnings-500">{t('required')}</small>}

        <div className="flex justify-center my-5">
          <button className="w-[100%] rounded-full py-2 text-base font-semibold bg-primary text-white cursor-pointer">
            {t('continue')}
          </button>
        </div>
        <div className="flex justify-center">
          <p className="pr-2">{t('alreadyHaveAccount')}? </p>
          <p
            onClick={CloseSession}
            className="hover:text-indigo-500 text-secondary-500 underline text-base"
          >
            {t('login')}
          </p>
        </div>
        <div className="flex justify-center">
          <p className="pr-2">{t('bySing')}</p>
          <a
            href={TERMS}
            target="_blank"
            rel="noreferrer"
            className="hover:text-indigo-500 text-secondary-500 underline text-base"
          >
            {t('terms')}
          </a>
        </div>
      </form>
    </div>
  );
};

export default Form;
