import Input from 'components/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getEmailRecovery, getPassword } from 'store/business/selectors';
import { CloseSession, ConfirmCode, LoginUser, ResendConfirmationCode } from 'actions/auth.api';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import { useEffect, useState } from 'react';
import { REACT_APP_CDN } from 'utils/enviroment';
import { GetBusinessThunk, GetBusinessUserIdThunk } from 'store/business/api.thunks';
import { useAppDispatch } from 'store';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { setPassword } from 'store/business';

interface IConfirm {
  code: string;
}

const confirmEmail = (): JSX.Element => {
  const emailRecovery = useSelector(getEmailRecovery);
  const password = useSelector(getPassword);
  const { trackPageView } = useMatomo();
  const [t] = useTranslation(['ConfirmEmail']);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirm>();

  const onSubmit: SubmitHandler<IConfirm> = ({ code }) => {
    if (emailRecovery) {
      setLoading(true);
      ConfirmCode(emailRecovery, code)
        .then(() => {
          toast.success(t('success'));
          if (emailRecovery && password) {
            return LoginUser({ email: emailRecovery, password: password }).then((result) => {
              if (result) {
                sessionStorage.clear();
                dispatch(setPassword(null));
                dispatch(GetBusinessUserIdThunk(result.getIdToken().payload.sub))
                  .unwrap()
                  .then((result) => {
                    if (!result.isActive) {
                      toast.error(t('notFound'));
                      CloseSession();
                    } else if (result.businessId) {
                      dispatch(GetBusinessThunk(result.businessId));
                      navigate(RoutesConstant.secure.shift);
                    } else {
                      navigate(RoutesConstant.secure.onBoarding);
                    }
                  });
              } else {
                navigate(RoutesConstant.unsecure.login);
              }
            });
          }
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const onResendCode = () => {
    if (emailRecovery) {
      setLoading(true);
      ResendConfirmationCode(emailRecovery)
        .then(() => {
          toast.success(t('successResend'));
        })
        .catch((e) => toast.error(e.message))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!emailRecovery) {
      navigate(RoutesConstant.unsecure.login);
    }
  }, [emailRecovery]);

  useEffect(() => {
    trackPageView({
      documentTitle: 'Confirm Email',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="flex h-[100vh] bg-[#E5E5E5] w-full">
      <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 flex self-center">
        <div className="w-full">
          <p className="w-full text-lg md:text-xl mt-3 mb-5 text-pr">
            {t('message')} <a className="text-secondary-500">{emailRecovery}</a>
            {`, ${t('verify')}`}
          </p>
          <div className="w-full flex justify-center md:hidden mb-3">
            <img className="w-80" src={`${REACT_APP_CDN}/Coder.svg`} alt="" />
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full">
              <Input
                customStyle="mt-4 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                name="code"
                placeholder={'Code'}
                register={register}
                type="text"
                errors={errors.code}
                validation={{
                  required: true,
                }}
              />
            </div>

            <div className="flex flex-wrap justify-between px-12 md:justify-between my-5 md:my-10 w-full">
              <button
                className="w-[40%] rounded-full py-2 font-semibold bg-primary text-white"
                type="submit"
                disabled={loading}
              >
                {t('confirm')}
              </button>
              <button
                className="w-[40%] md:my-0 rounded-full py-2 font-semibold border border-primary"
                onClick={onResendCode}
                type={'button'}
                disabled={loading}
              >
                {t('resend')}
              </button>
            </div>
            <div className="flex items-center justify-center ">
              <p className="text-sm font-semibold mr-1">{t('alreadyHaveAccount')} </p>
              <Link
                to={RoutesConstant.unsecure.login}
                className="text-[#117AF4] py-1 rounded-md text-center text-sm font-semibold underline underline-offset-2"
              >
                {t('login')}
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
        <div className="self-center ">
          <img src={`${REACT_APP_CDN}/Coder.svg`} alt={`${REACT_APP_CDN}/Coder.svg`} />
        </div>
      </div>
    </div>
  );
};

export default confirmEmail;
