import { useTranslation } from 'react-i18next';
import ShiftsTablePresenter from './ShiftsTable.Presenter';
import { createContext } from 'react';
import { DateTime } from 'luxon';
import ReactPaginate from 'react-paginate';
import Icon from 'assets/icons';
import Button from 'components/Button';
import Calendar from 'pages/shift/shared/components/date-picker';
import SelectV2 from 'components/SelectV2';
import DotMenu from 'components/DotMenu';
import Skeleton from 'react-loading-skeleton';
import InputFilter from 'pages/shift/shared/components/filter-input';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import { TIME_ZONE } from 'utils/constants/days';
import { Disclosure } from '@headlessui/react';
import Lottie from 'lottie-react';
import NotFound from 'assets/lottie/data-notfound.json';
import useWindowDimensions from 'utils/hooks/window';

const ShiftsTableContext = createContext({});
const ShiftsTableScreen = () => {
  const [t] = useTranslation(['Shifts']);
  const presenter = ShiftsTablePresenter();
  const now = new Date();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  return (
    <ShiftsTableContext.Provider value={presenter}>
      <div className="p-4 border rounded-xl mx-3">
        <div className="flex justify-between pb-4">
          <div className=" flex items-center flex-wrap ">
            <InputFilter
              className="font-semibold rounded-full mb-3 xl:mb-0 mr-2"
              placeholder={t('search')}
              onChange={(e) => presenter.setQuery(e.target.value)}
              prefixIcon={<Icon icon="search" size={12} className="text-neutral-800" />}
            />
            <div className="mr-2  mb-3 xl:mb-0">
              <Calendar onChange={presenter.setStartDate} value={presenter.startDate} />
            </div>

            <div className="mr-2  mb-3 xl:mb-0">
              <SelectV2
                showDropDownArrow
                itemSelected={presenter.selectedTime}
                className="border-[1px] rounded-full py-0.5 px-5 ml-1 "
                items={[
                  {
                    id: '1',
                    text: t('upcoming'),
                  },
                  {
                    id: '2',
                    text: t('past'),
                  },
                ]}
                name={t('upcoming')}
                setSelectedItem={presenter.setSelectedTime}
              />
            </div>

            <div className="mr-2  mb-3 xl:mb-0">
              <Button
                onClick={presenter.onClickResetFilter}
                size="sm"
                name={t('reset')}
                color="outline"
                className="text-secondary-500 border-neutral-800"
              />
            </div>
          </div>

          <div className=" flex flex-wrap">
            <Button
              name={t('download')}
              color="outlineSecondary"
              size="sm"
              className="mr-2 py-2  mb-3 xl:mb-0 max-h-[57px] rounded sm:rounded-full"
              onClick={presenter.onClickCode}
              prefixIcon={
                width > 1000 ? <Icon icon="qr" size={15} className="-mt-0.5" /> : undefined
              }
            />

            <Disclosure>
              <div className="flex flex-col">
                <Disclosure.Button className="bg-secondary-500 text-white rounded-lg sm:rounded-3xl py-2 px-2 sm:px-6">
                  {width > 1000 && <Icon icon="add" size={15} className="-mt-0.5 mr-2" />}
                  {t('createShift').toString()}{' '}
                  {width > 1000 && (
                    <Icon icon="arrowRight" size={10} className="text-white -mt-0.5 rotate-90" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className="absolute mt-12 -ml-4 bg-neutral-200 rounded-md  font-semibold text-neutral-1200 shadow-lg">
                  <p className="my-2 mx-3 cursor-pointer" onClick={presenter.onClickCreateShift}>
                    {t('createManual')}
                  </p>
                  <hr className="bg-neutral-1200 h-0.5" />
                  <p
                    onClick={presenter.onClickCreateManualShift}
                    className="my-2 mx-3 cursor-pointer"
                  >
                    {t('createExcel')}
                  </p>
                </Disclosure.Panel>
              </div>
            </Disclosure>
          </div>
        </div>
        <div className=" overflow-x-scroll">
          <table className="w-full">
            <thead className="bg-neutral-200 ">
              <tr className="px-6 py-3">
                <th className="text-xs min-w-32 font-normal text-left pl-6 py-3">
                  {t('table.date')}
                </th>
                <th className="text-xs min-w-32 font-normal text-left">{t('table.title')}</th>
                <th className="text-xs min-w-32 font-normal text-left">{t('table.location')}</th>
                <th className="text-xs min-w-32 font-normal text-left">{t('table.start')}</th>
                <th className="text-xs min-w-32 font-normal text-left">{t('table.end')}</th>
                <th className="text-xs min-w-20 font-normal text-left">{t('table.opening')}</th>
                <th className="text-xs min-w-14 font-normal text-left">{t('table.payRate')}</th>
                <th className="text-xs min-w-20 font-normal text-left">{t('table.total')}</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {presenter.getQueryPostedShifts.data?.resultShiftPagination.shiftListItems.map(
                (e, key) => {
                  const timezone =
                    TIME_ZONE(t).find((timezone) => timezone.id === e.timeZone) ?? TIME_ZONE(t)[0];

                  return (
                    <tr
                      className={`border-b-[1px] text-gray-1200 ${!e.isActive && 'bg-neutral-400/50'}`}
                      key={key}
                    >
                      <td className="py-5 pl-4 text-xs font-normal text-left">
                        {DateTime.fromISO(e.startDate).toLocaleString(DateTime.DATE_MED)}
                      </td>
                      <td
                        className="py-5 text-xs font-normal text-left text-secondary-500 underline cursor-pointer"
                        onClick={() => presenter.onClickDetails(e.id)}
                      >
                        {e.positionTitle}
                      </td>
                      <td className="py-5 text-xs font-normal text-left truncate">{e.location}</td>
                      <td className="py-5 text-xs font-normal text-left">
                        {DateTime.fromISO(e.startDate).setZone(timezone.key).toFormat('hh:mm a')} (
                        {timezone.label})
                      </td>
                      <td className="py-5 text-xs font-normal text-left">
                        {DateTime.fromISO(e.endDate).setZone(timezone.key).toFormat('hh:mm a')} (
                        {timezone.label})
                      </td>
                      {DateTime.fromISO(e.endDate).toJSDate() < now ? (
                        <td className="py-5 text-xs font-normal text-center ">
                          <p className=" bg-neutral-400 border text-neutral-1200 border-neutral-1200 w-10/12 rounded-full py-1">
                            {e.totalApplicants}/{e.openings}
                          </p>
                        </td>
                      ) : e.totalApplicants == e.openings ? (
                        <td className="py-5 text-xs font-normal text-center ">
                          <p className=" bg-success-background border text-success-border border-success-border w-10/12 rounded-full py-1">
                            {e.totalApplicants}/{e.openings}
                          </p>
                        </td>
                      ) : (
                        <td className="py-5 text-xs font-normal text-center ">
                          <p className=" bg-secondary-200 border text-secondary-500 border-secondary-500 w-10/12 rounded-full py-1">
                            {e.totalApplicants}/{e.openings}
                          </p>
                        </td>
                      )}

                      <td className="py-5 text-xs font-normal text-left">
                        ${e.salaryAmount.toFixed(2)}
                      </td>
                      <td className="py-5 text-xs font-normal text-left">
                        ${e.totalCost.toFixed(2)}
                      </td>

                      <td className="py-5 text-xs font-normal text-left">
                        {e.isActive && (
                          <DotMenu
                            data={[
                              {
                                displayName: 'Edit',
                                func: () =>
                                  navigate(RoutesConstant.secure.postShift + '?id=' + e.id),
                              },
                              {
                                displayName: 'Delete',
                                func: () => presenter.onClickDeleteShift(e.id),
                              },
                            ]}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>

        {presenter.getQueryPostedShifts.data?.resultShiftPagination.totalItems === 0 && (
          <div className="flex justify-center">
            <div style={{ width: 350 }}>
              <Lottie
                rendererSettings={{
                  preserveAspectRatio: 'xMidYMid slice',
                }}
                className="m-auto"
                animationData={NotFound}
                loop={true}
              />
            </div>
          </div>
        )}

        {presenter.getQueryPostedShifts.isLoading && (
          <Skeleton count={10} width={'100%'} height={40} />
        )}

        <div className="w-full m-auto">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={presenter.handlePageClick}
            pageRangeDisplayed={10}
            pageCount={Math.ceil(
              (presenter.getQueryPostedShifts.data?.resultShiftPagination.totalItems ?? 0) / 10
            )}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={'flex justify-center items-center mt-7'}
            pageLinkClassName={'text-base text-secondary-500 px-3 py-2 rounded-lg mx-1'}
            previousLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
            nextLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
            activeLinkClassName={'text-white bg-secondary-500'}
          />

          <presenter.Confirmation />
        </div>
      </div>
    </ShiftsTableContext.Provider>
  );
};

export default ShiftsTableScreen;
