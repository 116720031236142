import { TFunction } from 'react-i18next';
import CreateTemplateSet from '../../../../../shift/core/Modules/Shifts/Domain/Dtos/CreateTemplateSet.dto';
import { EventDataGet } from '../../Catalog/Domain/Entities/Catalog.entity';
import { dtoTemplateToForm } from '../Domain/Mappers/dtoToEntityForm.mapper';
import { TemplateRepository } from '../Domain/Repository/Template.repository';
import { DataFormType } from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import { TemplateDetailGet } from '../Domain/Entities/TemplateDetail.entity';
import QueryString from 'qs';
import { ShiftInfoFormType } from 'pages/shift/core/Modules/Shifts/Domain/Dtos/CreateShiftTemplate.dto';

export class TemplateUseCase {
  private templateRepository = new TemplateRepository();

  public async invokeCreateTemplate(data: CreateTemplateSet) {
    return this.templateRepository.createTemplate(data);
  }

  public async invokeGetTemplate(id: string, t: TFunction, search: string, catalog?: EventDataGet) {
    const result = await this.templateRepository.getTemplateDetail(id);

    const finalObject: { form: DataFormType; data: TemplateDetailGet } = {} as {
      form: DataFormType;
      data: TemplateDetailGet;
    };

    const templateDates = QueryString.parse(search.replace('?', ''));

    if (result && catalog) {
      const form = dtoTemplateToForm(
        result,
        catalog,
        t,
        templateDates as unknown as ShiftInfoFormType
      );
      finalObject.form = form;
    }

    if (result) {
      finalObject.data = result;
    }

    return finalObject ?? undefined;
  }
}
