import OnBoarding from 'pages/onBoarding';
import payment from 'pages/payment';
import AddCard from 'pages/payment/addCard';
import Settings from 'pages/settings';
import Profile from 'pages/profile';
import Loading from 'pages/loading/indext';
import LoginScreen from 'pages/login';
import EventQr from 'pages/AdditionalContent';
import RegisterScreen from 'pages/register';
import ConfirmEmail from 'pages/confirmEmail';
import Forgot from 'pages/forgot';
import Welcome from 'pages/registerInvited/welcome';
import RegisterInvited from 'pages/registerInvited';
import { DateTime } from 'luxon';
import Professionals from 'pages/professionals';
import Support from 'pages/support';
import TimesheetPage from 'pages/timesheet';
import IndexShiftScreen from 'pages/shift/screens/Index/Index.Screen';
import ShiftDescriptionScreen from 'pages/shift/screens/ShiftDescription/ShiftDescription.Screen';
import PostShiftPage from 'pages/postShift/screens/page';
import IndexScreen from 'pages/massiveShift/screens/Index/Index.screen';
import IndexInvoiceScreen from 'pages/invoice/screens/Index/Index.Screen';
import HirePage from 'pages/hire/page';
import ForcePasswordPage from 'pages/forcePassword/page';

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const phoneRegex = /^(?!0+$)\d{10}$/;

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const LATITUDE = 'latitude';
export const LONGITUDE = 'longitude';
export const USER_ID = 'USER_ID';

export const RoutesConstant = {
  secure: {
    onBoarding: '/onboarding',
    payment: '/payments',
    addCard: '/payments/add',
    settings: '/settings',
    profile: '/profile',
    editProfile: '/profile/edit',
    loading: '/loading',
    shift: '/shift',
    professionals: '/professionals',
    invoice: '/invoice',
    support: '/support',
    timesheet: '/timesheet',
    postShift: '/postShift',
    createShiftExcel: '/massive-shifts',
    hire: '/hire',
  },
  unsecure: {
    home: '/',
    login: '/login',
    event: '/event',
    invoice: '/download-invoice',
    register: '/register',
    confirmEmail: '/confirmEmail',
    forgot: '/forgot',
    freeTrial: '/free',
    invitedWelcome: '/welcome',
    invitedRegister: '/invitedRegister',
    forcePassword: '/force-password',
    invoiceDetail: '/invoice',
  },
};

export const RoutesConstantComponent: IRoutesConstantComponent = {
  secure: [
    {
      route: '/onboarding',
      component: OnBoarding,
    },
    { route: '/payments', component: payment },
    { route: '/payments/add', component: AddCard },
    { route: '/settings', component: Settings },
    { route: '/profile', component: Profile },
    { route: '/loading', component: Loading },
    { route: '/shift', component: IndexShiftScreen },
    { route: '/shift/:id', component: ShiftDescriptionScreen },
    { route: '/professionals', component: Professionals },
    { route: '/invoice', component: IndexInvoiceScreen },
    { route: '/support', component: Support },
    {
      route: '/timesheet',
      component: TimesheetPage,
    },
    {
      route: '/postShift',
      component: PostShiftPage,
    },
    {
      route: '/massive-shifts',
      component: IndexScreen,
    },
    {
      route: '/hire',
      component: HirePage,
    },
  ],
  unsecure: [
    { route: '/', component: LoginScreen },
    { route: '/login', component: LoginScreen },
    { route: '/event', component: EventQr },
    { route: '/register', component: RegisterScreen },
    { route: '/confirmEmail', component: ConfirmEmail },
    { route: '/forgot', component: Forgot },
    { route: '/welcome', component: Welcome },
    { route: '/invitedRegister', component: RegisterInvited },
    { route: '/force-password/:id', component: ForcePasswordPage },
  ],
};

export enum RoleConstant {
  ADMIN,
  MEMBER,
}

export enum PlanType {
  Free,
  Subscription,
}

export enum DefaultPrices {
  PerPostSingle = 49.99,
  PerPostThree = 89.99,
  Subscription = 499.99,
}

export enum ApplicantsStatus {
  received,
  reviewed,
  interested,
  hired,
  rejected,
  hided,
  interview,
}

export const Role = ['Admin', 'General'];

export enum Languages {
  EN = 'en',
  ES = 'es',
}

export enum LangEnum {
  EN = 'EN',
  ES = 'ES',
}

export const colors = [
  '#FF9900',
  'red',
  '#00214C',
  '#3564A0',
  '#FF9900',
  'red',
  '#00214C',
  '#3564A0',
];

export const TERMS = 'https://lachamba.app/terminos';

export const initialValues = {
  openings: '',
  title: '',
  description: '',
  shift: { id: '0', text: 'Select work time' },
  type: { id: '0', categoryId: '0', text: 'Role' },
  salaryType: { id: '0', text: 'Compensation type' },
  salary: '',
  salaryAmountMax: '',
  requiredPermision: { id: '0', text: 'Requires permission to work in the USA' },
  experienceRequired: { id: '100', text: 'Experience required' },
  walkInInterview: '',
};

export interface ICarouselItems {
  id: string;
  text: string;
  image: string;
}

// for shifts
export const carouselData: Array<ICarouselItems> = [
  {
    id: '175c8445-7843-46dd-b113-cce420074c18',
    text: 'Server',
    image: 'server',
  },
  {
    id: '309d648d-2461-47ea-9cb7-8062fca1d089',
    text: 'Bartender',
    image: 'bartender',
  },
  {
    id: '42d34ab1-d677-437d-be8f-66839b160ea5',
    text: 'Cleaning',
    image: 'sanitation',
  },
  {
    id: '6aac9500-cbbe-495f-a4f7-a791e6919877',
    text: 'Dishwasher',
    image: 'dishwasher',
  },
  {
    id: 'a881a1ae-8d9f-40d3-bfac-61709eb52ade',
    text: 'Busser',
    image: 'bussier',
  },
  {
    id: 'c0b55f62-d223-480f-99ce-4d276c382423',
    text: 'Cashier',
    image: 'cashier',
  },
  {
    id: 'c579f31d-e2c6-44dc-8772-2a7f30742673',
    text: 'Cook',
    image: 'prep-cook',
  },
  {
    id: '37127dd5-ca14-49e4-8358-8cf7791f6366',
    text: 'Housekeeper',
    image: 'housekeeping',
  },
  {
    id: '9d7bfbbc-05c0-4e05-9c75-b6bdcbb03c4f',
    text: 'Construction',
    image: 'construction',
  },
  {
    id: 'a745fd6c-f335-4a9f-ae7e-78d13213bdcc',
    text: 'Production / Warehouse',
    image: 'warehouse',
  },
  {
    id: '059ef2a0-b5a2-4db4-9496-3ae5adbfaa1e',
    text: 'Event helper',
    image: 'event-helper',
  },
  {
    id: '58e9bed6-beda-45d0-a225-59aa0384faa1',
    text: 'Other',
    image: 'other',
  },
];

export const defaultTZ = DateTime.local().zoneName;
export const ITEMS_PER_PAGE = 10;

export enum SIDEBAR_NAV {
  SHIFTS = 1,
  TIMESHEET = 9,
  PAYMENT = 2,
  SETTINGS = 3,
  PROFESSIONALS = 4,
  PROFILE = 5,
  INVOICE = 6,
  PROFILES = 7,
  SUPPORT = 8,
  HIRE = 10,
}

export enum SHIFT_APPLICANT_STATUS {
  ACCEPTED = 1,
  REJECTED = 2,
  PAID = 3,
  VALIDATION = 4,
  GHOSTED = 5,
}
