type LocationInfo = {
  english: string;
  spanish: string;
};

type Contact = {
  name: string;
  role: string;
  phoneNumber: string;
  email: string;
};

type DataItem = {
  id: string;
  businessId: string;
  templateName: string;
  location: {
    topic: string;
    address: string;
    notificationsTopic: string;
  };
  locationCoords: {
    latitude: number;
    longitude: number;
  };
  parkingInfo: string;
  locationInfo: LocationInfo | null;
  clockInInfo: LocationInfo;
  positionTitle: LocationInfo;
  positionId: string;
  startDate: string;
  endDate: string;
  breakTime: number;
  openings: number;
  salaryType: string;
  salaryAmount: number;
  fullTimeOportunity: boolean;
  uniform: string;
  dressCode: string;
  groomingRestrictions: string[];
  jobInformation: LocationInfo;
  abilities: string[];
  experience: string;
  contacts: Contact[];
  onlyFavorites: boolean;
  creationDate: string;
};

export class TemplateListGet {
  constructor(
    public list: {
      metadata?: {
        total?: number;
        page?: number;
      };
      nextPage: boolean;
      data: DataItem[];
    }
  ) {}
}
