import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import CardFormPresenter from './CardForm.Presenter';
import Button from 'components/Button';
import FloatingDialog from 'components/FloatingDialog';
import Lottie from 'lottie-react';
import PasswordAnimation from '../../../../assets/lottie/success-payment.json';

const CardForm = () => {
  const presenter = CardFormPresenter();

  return (
    <div className="text-center">
      <div>
        <CardNumberElement
          options={{
            disabled: presenter.isLoading,
          }}
          onReady={() => presenter.setReadyCard({ ...presenter.readyCard, number: true })}
          className="mt-4 bg-white px-3 shadow-md text-lg sm:text-lg appearance-none rounded-lg relative block w-full py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
        />
      </div>

      <div className="flex">
        <CardExpiryElement
          options={{
            disabled: presenter.isLoading,
          }}
          onReady={() => presenter.setReadyCard({ ...presenter.readyCard, expiry: true })}
          className="w-1/2 bg-white p-5 shadow-md mt-4 mr-1 font-light sm:text-lg appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
        />
        <CardCvcElement
          onReady={() => presenter.setReadyCard({ ...presenter.readyCard, ccv: true })}
          options={{
            disabled: presenter.isLoading,
          }}
          className="w-1/2 bg-white p-5 shadow-md mt-4  text-sm sm:text-lg appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
        />
      </div>

      <br />

      <Button
        type="submit"
        onClick={presenter.onSubmit}
        loading={presenter.isLoading}
        disabled={!presenter.isReadyForPayment || presenter.isLoading}
        className="bg-primary border mt-9 border-white py-1 mb-5 text-white px-12 font-semibold rounded-lg m-auto"
      >
        Pay
      </Button>

      <FloatingDialog
        setIsOpen={() => {
          //
        }}
        isOpen={presenter.succeeded}
      >
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-center text-2xl text-primary uppercase font-bold">Success payment</h2>
          <p className="font-light">Now you can leave page</p>
          <p className="mt-5">ID({presenter.id})</p>
          <Lottie className="w-full" animationData={PasswordAnimation} loop={false} />
        </div>
      </FloatingDialog>
    </div>
  );
};

export default CardForm;
