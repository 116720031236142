import { IOnboardData } from 'actions/catalog/catalog.api';

export interface IChangePaymentMethod {
  id: string;
  paymentOption: PaymentOptionEnum;
  invoiceEmails?: string[];
}

export interface UpdateLang {
  id: string;
  language: string;
}

export enum PaymentOptionEnum {
  NotSelected = 0,
  AutoCharge = 1,
  InvoicePerShift = 2,
  InvoicePerDay = 3,
  InvoicePerWeek = 4,
}

export enum Country {
  Colombia = 2,
  UnitedStates = 1,
}

export interface ICompany {
  id: string;
  name: string;
  description: string;
  logo: string;
  email: string;
  phoneNumber: string;
  type: string;
  locations?: string;
  discoveryOption?: string;
  creationDate?: Date;
  isActive?: boolean;
  size: string;
  plan?: string;
  role: string;
  serviceFee: number;
  subscription: {
    stripeId: string;
    planType: number;
    endDate: string;
    postRemaining: number;
  };
  paymentOption?: PaymentOptionEnum;
  taxType?: TaxTypeEnum;
  isMarkdown?: boolean;
  invoicePayment?: number;
  invoiceEmails?: string[];
  country: Country;
}

export interface IFullBusinessUser {
  id: string;
  name: string;
  email: string;
  authMethod: number;
  cognitoId: string;
  businessId: string;
  role: number;
  position: string;
  creationDate: string;
  isActive: true;
}

export interface IOnBoarding {
  name: string;
  email: string;
  phoneNumber: string;
  type: IItems;
  discoveryOption: IItems;
  businessUserId: string;
  role: string;
}

export type IOnBoardingApi = Omit<IOnBoarding, 'type' | 'discoveryOption' | 'role'>;

export interface IOnBoardingApiRequest extends IOnBoardingApi {
  type: string;
  discoveryOption: string;
  taxType: TaxTypeEnum;
  country: number;
}

export interface IGetInvoiceDetails {
  invoiceId: string;
  consecutive: number;
  paymentOption: PaymentOptionEnum;
  invoiceDate: string;
  businessName: string;
  startDate: string;
  endDate: string;
  positionTitle: string;
  totalPros: number;
  serviceFee: number;
  totalHours: number;
  totalAmount: number;
  isMarkdown: boolean;
  details: [
    {
      day: string;
      applicants: [
        {
          name: string;
          positionTitle: string;
          isTotalCompensation: true;
          salaryTotal: number;
          salaryHour: number;
          clockIn: string;
          clockOut: string;
          breakTime: number;
          laborCost: number;
        },
      ];
    },
  ];
}

export interface IProfileContext {
  data?: IOnboardData;
  setAddBusinessUser: (v: boolean) => void;
  addBusinessUser: boolean;
}

export enum TaxTypeEnum {
  F_1099 = 1,
  W2 = 2,
}

export interface IDeleteRequestData {
  leavingReason: string;
  opinion?: string;
  businessId: string;
}

export interface IEditProfileApiRequest {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  type: string;
}

export interface IPostMassive {
  eventIds: string[];
  paymentMethod?: string;
}

export interface IGetWorkHours {
  userId: string;
  businessId: string;
}

export interface IGetWorkHoursResult {
  businessId: string;
  hours: number;
  userId: string;
  amount: number;
}

export interface IPostHire {
  businessId: string;
  businessUserId: string;
  userId: string;
  hireAmount: number;
  positionTitle: string;
  startDate: string;
  firstDayInstructions?: string;
  paymentType: string;
  paymentAmount: number;
  benefits: string;
  location: {
    topic?: string;
    address?: string;
    notificationsTopic?: string;
  };
  locationCoords: {
    latitude: number;
    longitude: number;
  };
}
