import { REACT_APP_API } from 'utils/enviroment';

export const API_ENDPOINTS = {
  BUSINESS: {
    SIGNUP_EMAIL: '/business/create-business-user',
    SIGNUP_INVITED_BUSINESS: '/business/create-invited-business-user',
    GET_BUSINESS: '/business/get-business/{id}',
    SIGN_FEDERATED_BUSINESS: '/business/signin-federated-business-user',
    ONBOARD_BUSINESS: '/business/onboard-business',
    GET_BUSINESS_COGNITO: '/business/get-business-user-cognito/{id}',
    REGISTER_COGNITO: '/business/sign-federated-business-user',
    EDIT_PROFILE: '/business/edit-business',
    GET_ALL_BUSINESS: '/business/get-active-business-users/{id}',
    DELETE_BUSINESS_USER: '/business/delete-business-user/{id}',
    INVITE_BUSINESS: '/business/invite-business-user',
    UPDATE_BUSINESS_ROLE: '/business/edit-business-user-role',
    UPDATE_PROFILE_PHOTO: '/business/edit-business-logo',
    MANAGE_NOTIFICATIONS: '/business/manage-notifications',
    UPDATE_LANGUAGE: '/business/edit-business-user-language',
    UPDATE_PAYMENT_OPTION: '/business/edit-business-payment-option',
    GET_INVOICE: '/search/get-invoice',
    DELETE_BUSINESS: '/business/delete-business',
    GET_RATE: '/search/get-business-rate',
  },
  PAYMENTS: {
    LIST_CARDS: '/business/get-business-card/{id}',
    UPDATE_DEFAULT: '/business/update-default-card',
    ADD_CARD: '/business/register-business-card',
    DELETE_CARD: '/business/delete-business-card/{id}',
    CREATE_SUBSCRIPTION: '/business/create-subscription',
    DELETE_SUBSCRIPTION: '/business/delete-subscription',
    PAY_INVOICE: '/shiftbilling/pay-invoice',
  },
  CATALOG: {
    GET_ONBOARD_DATA: '/catalog/get-business-onboard-data',
    GET_CATEGORIES: '/catalog/get-post-manager-filters-data',
    GET_EVENT_DATA: '/catalog/get-event-data',
    GET_DELETE_REASONS: '/catalog/get-delete-business-data',
    GET_BUSINESS_RATE_DATA: '/catalog/get-business-rate-data',
    GET_HIRE_DATA: '/catalog/get-hire-data',
  },
  INTEGRATION: {
    GET_PLACE_BY_COORDS: '/integration/get-place-by-coords',
    GET_PLACE_AUTOSUGGEST: '/integration/get-autosuggest/{text}',
    GET_PLACE_BY_ID: '/integration/get-place-by-id/{id}',
    CREATE_DYNAMIC_LINK: '/FCM/create-dynamic-link',
    UPLOAD_EXCEL: '/integration/bulk-shift-load',
  },
  SEARCH: {
    GET_SHIFT_APPLICANTS: '/search/get-shift-applicants',
    GET_EVENT_FILTERS: '/search/get-event-filters/{id}',
    GET_SHIFT_FILTERS: '/search/get-shift-filters/{id}',
    GET_POSTED_SHIFTS: '/search/get-posted-shifts',
    GET_SHIFT_DESCRIPTION: '/search/get-shift-description',
    GET_RESUME: '/search/get-short-profile',
    GET_GENERAL_JOBSEEKERS: '/search/get-general-jobseekers',
    GET_PREFERRED_JOBSEEKERS: '/search/get-preferred-jobseekers',
    GET_BLOCKED_JOBSEEKERS: '/search/get-blocked-jobseekers',
    GET_RECENT_JOBSEEKERS: '/search/get-recent-jobseekers',
    GET_DAY_INVOICE_CARDS: '/search/get-day-invoice-cards',
    GET_DAY_INVOICE_DESCRIPTION: '/search/get-day-invoice-description',
    GET_SHIFT_INVOICE_CARDS: '/search/get-shift-invoice-cards',
    GET_SHIFT_INVOICE_DESCRIPTION: '/search/get-shift-invoice-description',
    GER_WEEK_INVOICE_CARDS: '/search/get-week-invoice-cards',
    GET_SHIFT_PAGINATED: '/search/get-shifts-paginated',
    GET_TIMESHEET_WEEK: '/search/get-timesheet-week',
    GET_TIMESHEET: '/search/get-timesheet',
    GET_SHIFT_LIST: '/search/get-shift-list',
    GET_BUSINESS_INVOICES: '/search/get-business-invoices',
    GET_INVOICE_DETAIL: '/search/get-invoice',
    GET_HIRE_LIST: '/search/get-business-hires',
    GET_HIRE_DESCRIPTION: '/search/get-hire-description',
  },
  USERS: {
    CHANGE_APPLIED_JOB_STATUS: '/jobseekers/change-applied-job-status',
    GET_USER_MONGO: '/jobseekers/get-user-mongo',
    GET_RESUME: '/jobseekers/get-resume',
    GET_RESUME_PDF: '/jobseekers/get-resume-pdf',
    DELETE_APPLICANT: '/jobseekers/delete-job-applicant/{id}',
    CREATE_PREFERRED_USER: '/jobseekers/create-preferred-user',
    CREATE_BLOCKED_USER: '/jobseekers/create-blocked-user',
    DELETE_PREFERRED_USER: '/jobseekers/delete-preferred-user/{id}',
    DELETE_BLOCKED_USER: '/jobseekers/delete-blocked-user/{id}',
  },
  EVENTS: {
    POST_EVENT: '/events/post-event',
    POST_EVENT_CHANGE_STATUS: '/events/change-shift-application-status',
    GET_EVENT: '/events/get-shift/{id}',
    POST_EDIT_EVENT: '/events/edit-shift',
    CREATE_BUSINESS_RATE: '/events/create-business-rate',
    GET_SHIFT_TEMPLATE: '/events/get-shift-templates-by-business/{id}',
    GET_TEMPLATE_DETAIL: '/events/get-shift-template/{id}',
    CREATE_SHIFT_TEMPLATE: '/events/create-shift-template',
    DELETE_SHIFT_TEMPLATE: '/events/delete-shift-template/{id}',
    UPDATE_SHIFT_TEMPLATE: '/events/edit-shift-template-name',
    CREATE_INDIVIDUAL_RATE: '/events/create-individual-business-rate',
    EDIT_SHIFT_EXECUTION_TIP: '/events/edit-shift-execution-tips',
    APPROVE_TIMESHEET: '/events/approve-timesheets',
    CREATE_DISPUTE: '/events/create-dispute',
    SAVE_DRAFT_DATA: '/events/save-draft-event',
    GET_DRAFT_DATA: '/events/get-current-draft-event/{id}',
    DELETE_DRAFT_DATA: '/events/delete-draft-event/{id}',
    POST_DRAFT: '/events/post-draft-event',
    POST_MASSIVE: '/events/post-draft-events',
    GET_ROLE: '/events/get-shift-roles-by-business/{id}',
    SAVE_ROLE: '/events/save-shift-role',
    EDIT_ROLE: '/events/save-shift-role',
    GET_CONTACT: '/events/get-shift-contacts-by-business/{id}',
    SAVE_CONTACT: '/events/save-shift-contact',
    GET_LOCATION: '/events/get-shift-locations-by-business/{id}',
    SAVE_LOCATION: '/events/save-shift-location',
    DELETE_SHIFT: '/events/delete-shift/{id}',
    GET_USER_WORKED_HOURS: '/events/get-user-worked-hours-for-business',
    CREATE_HIRE: '/hires/post-hire',
  },
};

export const getEndpoint = (endpoint: string, params = {}): string => {
  return REACT_APP_API + createApiEndpoint(endpoint, params);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createApiEndpoint = (url: string, params: any) => {
  const pattern = /\{([^\\{\\}]*)\}/g;
  return url.replace(pattern, (a, b) => {
    const p = b.split('.');
    let r = params;

    try {
      for (let s = 0; s < p.length; s++) {
        r = String(r[p[s]]);
      }
    } catch (e) {
      r = a;
    }

    if (typeof r === 'undefined') {
      r = 'undefined';
    } else if (r === null) {
      r = 'null';
    }

    return String(typeof r === 'string' || typeof r === 'number' ? r : typeof r);
  });
};
