export default {
  en: {
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    timeZones: {
      pacific: 'Pacific',
      mountain: 'Mountain',
      central: 'Central',
      eastern: 'Eastern',
    },
    search: 'Search by shift name',
    upcoming: 'Upcoming',
    past: 'Past',
    reset: 'Reset filter',
    download: 'Download code',
    createShift: 'Create shift',
    createManual: 'Create manual shift',
    createExcel: 'Create shift using excel',
    list: 'List',
    calendar: 'Calendar',
    templates: 'Templates',
    shift: 'Shift',
    next: 'Next',
    emails: 'Chamba will send invoice to the following emails',
    updateEmail: 'Update email',

    add: 'Add new card',
    SelectCard: 'Select card',
    draftDone: 'It is no longer possible to make changes, discard with the red button.',
    error: 'Complete the form fields',
    table: {
      date: 'Shift date',
      title: 'Shift title',
      location: 'Location',
      start: 'Start time',
      end: 'End time',
      opening: 'Openings',
      payRate: 'Pay rate',
      total: 'Est total cost',
    },
    description: {
      shiftInfo: 'Shift info',
      worker: 'Workers',
      pros: 'Pros',
      date: 'Date and positions',
      dateLocal: 'Date',
      startTime: 'Start time',
      endTime: 'End time',
      openings: 'Openings',
      address: 'Address',
      locationInfo: 'Location info',
      parkingInfo: 'Parking info',
      clockInfo: 'Clock-in instructions',
      contact: 'Contact information',
      skills: 'Skills',
      abilities: 'Abilities',
      experience: 'Experience',
      roleInfo: 'Role info',
      dressCode: 'Dress code',
      uniform: 'Uniform',
      attire: 'Attire provided',
    },
    proList: {
      clockIn: 'Clock in time',
      clockOut: 'Clock out time',
      totalCost: 'Total cost',
      cancelledWith: 'Canceled with in 24h',
      updated: 'User updated',
      workExperience: 'Work experience',
      education: 'Education',
      language: 'Language',
      spanish: 'Spanish',
      english: 'English',
      skills: 'Skills',
      addFavorites: 'Add to favorites',
      blockUser: 'Block user',
      reject: 'Reject applicant',
      hire: 'Hire',
    },
    create: {
      save: 'Save',
      back: 'Back',
      required: 'Field required',
      success: 'Shift created',
      post: 'Post a shift',
      contacts: {
        create: 'Create',
        description: 'Create on-site contact',
        contact: 'Contacts',
        name: 'Name',
        role: 'Role',
        email: 'Email',
        phone: 'Phone number',
        cancel: 'Cancel',
        save: 'Save',
      },
      confirmation: {
        edit: 'Edit',
        shiftName: 'Shift name',
        shiftTime: 'Shift time',
        from: 'From',
        mountainTime: 'Mountain time',
        description: 'Description',
        payment: 'Payment',
        abilities: 'Abilities required',
        instructions: 'Instructions',
        parking: 'Parking',
        pros: 'Number of pros',
        unpaid: 'Unpaid breaks',
        selectFrom: 'Select from',
        attire: 'Attire',
        dressCode: 'Dress code',
        restrictions: 'Grooming restrictions(not allowed)',
      },
      worker: {
        howMany: 'How many workers do you need?',
        workers: 'Number of workers',
        breaks: 'Unpaid breaks?',
        selectBreak: 'Select break time',
        inviteWorker: 'Which workers would you like to invite?',
        inviteWorkerDescription:
          'This shift can be offered to all Chamba Pros or only to your favorite Chamba Pros',
        autoAssign: 'Auto-assign pros',
        favorites: 'Only favorites',
        fullTime: 'Full time opportunity',
        yes: 'Yes',
        autoAssignDesc:
          'Shifts are offered to your Favorite Pros first, then to other qualified Pros',
        autoAssignFav: 'Shifts are offered to your Favorite Pros only',
      },
      role: {
        create: 'Create role',
        category: 'Select category',
        name: 'Role name',
        description: 'Role description',
        payment: 'Payment',
        paymentType: 'Type of payment',
        amount: 'Amount',
        tipped: 'Tipped position',
        yes: 'Yes',
        no: 'No',
        experience: 'Required experience',
        selectexperience: 'Select experience',
        skills: 'Required skills',
        selectSkills: 'Select one or more',
        hourly: 'Hourly',
        compensation: 'Total compensation',
      },
      location: {
        create: 'Create location',
        location: 'Shift location',
        parking: 'Parking',
        paid: 'Paid parking',
        free: 'Free',
        noParking: 'No parking',
        additional: 'Additional information e.g: Use the back door (Optional)',
        instructions: 'Clock-in instructions',
        available: 'Available',
      },
      confirm: {
        title: 'Shift info confirmation ',
        estimate: 'Estimated cost',
        opening: 'Total openings',
        hourly: 'Hourly rate',
        total: 'Total',
        checkout: 'Checkout',
        discard: 'Discard and start over',
        fee: 'Service fee',
        confirm: 'Continue with the confirmation',
        sure: 'Are you sure you want to discard all the information and start over?',
        cancel: 'Cancel',
        continue: 'Confirm',
        template: 'Do you want to save as template',
        templatePlaceholder: 'Template name',
      },
      shiftInfo: {
        role: 'Role',
        addDate: 'Add date',
        selectRole: 'Select role',
        createRole: 'Create new role',
        location: 'Location',
        selectLocation: 'Select location',
        createLocation: 'Create new location',
        date: 'Date',
        recurrent: 'Is this shift recurrent?',
        yes: 'Yes',
        betweenDates: 'Between which dates?',
        days: 'Which days?',
        mon: 'Mon',
        tue: 'Tue',
        wen: 'Wen',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun',
        weekdays: 'Select days of the week',
        selectAll: 'Select all',
        clear: 'Clear',
        time: 'Time',
        contact: 'Contact',
        selectContact: 'Select contact',
        createContact: 'Create new contact',
        startDate: 'Start date',
        endDate: 'End date',
        startTime: 'Start time',
        endTime: 'End time',
        contactsLeft: 'Add at least 1 contact',
        locationLeft: 'Location field required',
        roleLeft: 'You must enter role information',
        timeZone: 'Time zone',
        selectTZ: 'Select time zone',
        shiftRestriction: 'Can not book a shift longer than 14 hours',
      },
      attire: {
        provided: 'Is attire provided at venue?',
        select: 'Select an option',
        dress: 'Dress code (please select one)',
        grooming: 'Grooming restrictions (not allowed)',
        selectMore: 'Select one or more',
        mustSelect: 'You must select something',
        roleLeft: 'You must enter rol information',
      },
    },
    paymentModal: {
      title: 'Payment',
      save: 'Save this card for future purchases',
      info: `We only charge you after each shift is completed. Every shift is charged by the actual hours the professional works. You will receive an invoice via email which includes all the clock-in, clock-out info right after that.`,
      cancellation: 'Cancellation policy',
      policyOne: 'Free cancellation up to 24 hours before the shift or if the shift is not filled.',
      policyTwo:
        'Cancellation within 24 hours will be charged up to 4 hours of pay for any Professional booked on the shift.',
      agreeTerms: 'Book Chamba Pros”, you agree to our',
      terms: 'Terms and conditions',
      and: 'and',
      card: 'Card number',
      sendEmail: 'Invoice will send to the following emails',
      placeholderEmail: 'Select or add email',
    },
    chambaPros: 'Book Chamba Pros',
    template: {
      name: 'Template name',
      start: 'Start time',
      end: 'End time',
      pay: 'Payment',
      openings: 'Openings',
      location: 'Location',
    },
  },
  es: {
    days: {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    timeZones: {
      pacific: 'Pacífico',
      mountain: 'Montañas',
      central: 'Central',
      eastern: 'Este',
    },
    next: 'Siguiente',
    shift: 'Turnos',
    emails: 'Chamba enviará factura a los siguientes emails',
    updateEmail: 'Actualizar email',
    search: 'Busque por nombre del turno',
    upcoming: 'Próximos',
    past: 'Pasados',
    reset: 'Limpiar filtros',
    download: 'Descargar código',
    createShift: 'Solicitud de personal',
    createManual: 'Solicitud manual',
    createExcel: 'Crear solicitud con excel',
    list: 'Lista',
    calendar: 'Calendario',
    templates: 'Plantillas',

    add: 'Añadir nueva tarjeta',
    SelectCard: 'Seleccione una tarjeta',
    error: 'Complete los campos del formulario',
    draftDone: 'Ya no es posible hacer cambios, descarte con el botón rojo',
    edit: 'Editar',
    table: {
      date: 'Fecha del turno',
      title: 'Título del turno',
      location: 'Ubicación',
      start: 'Inicio',
      end: 'Finalización.',
      opening: 'Vacantes',
      payRate: 'Pago',
      total: 'Costo total aprox',
      locationInfo: 'Instrucciones del lugar',
      roleInfo: 'Información de la posición',
    },

    description: {
      shiftInfo: 'Info trabajo',
      worker: 'Trabajadores',
      pros: 'Pros',
      date: 'Date and openings',
      dateLocal: 'Fecha',
      startTime: 'Hora de inicio',
      endTime: 'Hora de fin',
      openings: 'Vacantes',
      address: 'Dirección',
      parkingInfo: 'Info del estacionamiento',
      clockInfo: 'Instrucciones de Clock-in',
      contact: 'Información de contacto',
      skills: 'Habilidades',
      abilities: 'Habilidades',
      experience: 'Experience',
      roleInfo: 'Información de la posición',
      dressCode: 'Código de vestir',
      uniform: 'Uniforme',
      attire: 'Uniforme proporcionado',
    },

    proList: {
      clockIn: 'Hora de inicio',
      clockOut: 'Hora de fin',
      totalCost: 'Costo total',
      cancelledWith: 'Cancelado en las 24h',
      updated: 'Usuario actualizado',
      workExperience: 'Experiencia laboral',
      education: 'Educación',
      language: 'Idioma',
      spanish: 'Español',
      english: 'Inglés',
      skills: 'Habilidades',
      addFavorites: 'Añadir a favoritos',
      blockUser: 'Bloquear usuario',
      reject: 'Rechazar aplicante',
      hire: 'Contratar',
    },

    create: {
      save: 'Guardar',
      back: 'Anterior',
      required: 'Campo requerido',
      success: 'Turno creado',
      post: 'Publicar trabajo',

      confirmation: {
        edit: 'Editar',
        shiftName: 'Nombre del turno',
        shiftTime: 'Hora del turno',
        from: 'Desde',
        mountainTime: 'Mountain time',
        description: 'Descripción',
        payment: 'Pago',
        abilities: 'Habilidades requeridas',
        instructions: 'Instrucciones',
        parking: 'Estacionamiento',
        pros: 'Número de pros',
        unpaid: 'Descansos no pagos',
        selectFrom: 'Seleccionar desde',
        attire: 'Uniforme',
        dressCode: 'Código de vestimenta',
        restrictions: 'Restricciones de aseo (no permitido)',
      },
      contacts: {
        create: 'Crear',
        description: 'Crear contacto',
        contact: 'Contacto principal',
        name: 'Nombre',
        role: 'Rol',
        email: 'Correo',
        phone: 'Número de teléfono',
        cancel: 'Cancel',
        save: 'Guardar',
      },
      worker: {
        howMany: 'Cuántos trabajadores necesitas?',
        workers: 'Cantidad de trabajadores',
        breaks: '¿Descansos no remunerados?',
        selectBreak: 'Selecciona descansos no renumerados',
        inviteWorker: '¿A qué trabajadores le gustaría invitar?',
        inviteWorkerDescription:
          'Este turno puede ofrecerse a todos los Pros de Chamba o sólo a sus Pros de Chamba favoritos',
        autoAssign: 'Mandar a todos los Pros cerca de mí',
        favorites: 'Solo favoritos',
        fullTime: 'Oportunidad de contratación permanente',
        yes: 'Sí',
        autoAssignDesc:
          'Los turnos se ofrecen primero a tus profesionales favoritos y luego a otros profesionales calificados',
        autoAssignFav: 'Los turnos se ofrecen sólo a sus profesionales favoritos',
      },
      role: {
        create: 'Crear rol',
        category: 'Seleccionar categoría',
        name: 'Nombre del rol',
        description: 'Descripción del rol',
        payment: 'Pago',
        paymentType: 'Tipo de pago',
        amount: 'Pago',
        tipped: 'Tipped position',
        yes: 'Sí',
        no: 'No',
        experience: 'Experiencia requerida',
        selectexperience: 'Seleccionar experiencia',
        skills: 'Habilidades requeridas',
        selectSkills: 'Selecciona una o más',
        hourly: 'Por hora',
        compensation: 'Compensación total',
      },
      location: {
        create: 'Crear ubicación',
        location: 'Ubicación del turno',
        parking: 'Estacionamiento',
        payed: 'Estacionamiento pagado',
        free: 'Gratuito ',
        noParking: 'No hay parqueo',
        additional: 'Información específica sobre el parqueadero (Opcional) ',
        instructions: 'Instrucciones de entrada',
        available: 'Disponible (Pago)',
      },
      confirm: {
        title: 'Confirmación de la información del turno',
        estimate: 'Costo estimado',
        opening: 'Vacantes abiertas',
        hourly: 'Costo por hora',
        total: 'Total',
        checkout: 'Checkout',
        discard: 'Descartar e iniciar de nuevo',
        fee: 'Comisión del servicio',
        confirm: 'Continuar conn confirmación',
        sure: '¿Estás seguro de que quieres descartar toda la información y empezar de nuevo?',
        cancel: 'Cancelar',
        continue: 'Confirmar',
        template: 'Desea guardarla como plantilla',
        templatePlaceholder: 'Nombre de la plantilla',
      },
      shiftInfo: {
        role: 'Rol',
        addDate: 'Agregar fecha',
        selectRole: 'Seleccionar rol',
        createRole: 'Crear nuevo rol',
        location: 'Ubicación',
        selectLocation: 'Seleccionar ubicación',
        createLocation: 'Crear nueva ubicación',
        date: 'Fecha',
        recurrent: '¿Este turno es recurrente?',
        yes: 'Sí',
        betweenDates: '¿Entre qué fechas?',
        days: '¿Qué días?',
        mon: 'Lun',
        tue: 'Mar',
        wen: 'Mie',
        thu: 'Jue',
        fri: 'Vie',
        sat: 'Sáb',
        sun: 'Dom',
        weekdays: 'Seleccionar días de la semana',
        selectAll: 'Seleccionar todos',
        clear: 'Limpiar',
        time: 'Hora',
        contact: 'Contacto',
        selectContact: 'Seleccionar contacto',
        createContact: 'Crear nuevo contacto',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de fin',
        startTime: 'Hora de inicio',
        endTime: 'Hora de fin',
        contactsLeft: 'Añade al menos 1 contacto',
        locationLefts: 'Campo de ubicación requerido',
        roleLeft: 'Debes ingresar la información del rol',
        timeZone: 'Zona horaria',
        selectTZ: 'Seleccionar zona horaria',
        shiftRestriction: 'No puede agendar un turno de más de 14 horas',
      },
      attire: {
        provided: '¿Se proporciona en uniforme en el lugar?',
        select: 'Selecciona una opción',
        dress: 'Código de vestimenta (por favor elige uno)',
        grooming: 'Restricciones de presentación personal (no permitido)',
        selectMore: 'Selecciona uno o más',
        mustSelect: 'Debes seleccionar algo',
      },
    },
    paymentModal: {
      title: 'Pago',
      save: 'Guardar esta tarjeta para futuras compras',
      info: `Sólo te cobramos una vez finalizado cada turno. Cada turno se cobra por las horas reales que trabaja el profesional. Recibirá una factura por correo electrónico que incluye toda la información de entrada y salida`,
      cancellation: 'Política de cancelación',
      policyOne:
        'Cancelación gratuita hasta 24 horas antes del turno o si el turno no está cubierto',
      policyTwo:
        'Cancelación dentro de 24 horas se cobrará hasta 4 horas de pago para cualquier Profesional reservado en el turno.',
      agreeTerms: 'Al hacer clic en "Pagar", aceptas nuestros',
      terms: 'Términos y condiciones',
      and: 'y',
      card: 'Número de tarjeta',
      sendEmail: 'Chamba le enviará la factura a los siguientes correos',
      placeholderEmail: 'Seleccione o cree un correo',
    },
  },
  chambaPros: 'Agendar Chamba pros',

  template: {
    name: 'Nombre de la plantilla',
    start: 'Hora de inicio',
    end: 'Hora de fin',
    pay: 'Pago',
    openings: 'Vacantes',
    location: 'Ubicación',
  },
};
