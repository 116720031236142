const dictionary = {
  'cloud-kitchens': {
    id: 'cff42c64-0f5f-401e-9778-03b30b58a56b',
    label: 'Cloud kitchens',
  },
};

type PartnerType = keyof typeof dictionary;

export const getCompanyCategory = (partner: string | null) => {
  const key = partner as PartnerType;

  if (partner) {
    return {
      id: dictionary[key].id,
      text: dictionary[key].label,
    };
  }

  return {
    id: '0',
    text: 'Select an option',
  };
};

export const getCompanyLabel = (partner: string | null) => {
  const key = partner as PartnerType;

  return dictionary[key]?.label;
};
