import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { getHours } from 'pages/postShift/shared/utils/date-calc';
import {
  ShiftInfoFormSchema,
  ShiftInfoFormType,
} from 'pages/shift/core/Modules/Shifts/Domain/Dtos/CreateShiftTemplate.dto';
import QueryString from 'qs';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';

const CreateShiftTemplatePresenter = (id: string) => {
  const form = useForm({
    resolver: yupResolver(ShiftInfoFormSchema),
  });

  const router = useNavigate();

  console.log(form.formState.errors);

  const times = form.watch('endTime');
  const isRecurrent = form.watch('recurrent');

  const onAddNewDate = () => {
    const start = form.getValues('startDate');

    if (Array.isArray(start)) {
      form.setValue('startDate', [...start, new Date()]);
    } else {
      if (start) {
        form.setValue('startDate', [start, new Date()]);
      } else {
        form.setValue('startDate', [new Date()]);
      }
    }
  };

  const selectDay = (day: number, value?: number[]) => {
    if (value) {
      if (value.includes(day)) {
        return [...value.filter((saved) => saved != day)];
      } else {
        return [day, ...value];
      }
    }

    return [day];
  };

  const onSubmit: SubmitHandler<ShiftInfoFormType> = (data) => {
    const finalObject = {
      ...data,
      ...(data.endTime
        ? { endTime: DateTime.fromJSDate(data.endTime).toFormat("yyyy-MM-dd'T'HH:mm:ss") }
        : undefined),
      ...(data.startTime
        ? { startTime: DateTime.fromJSDate(data.startTime).toFormat("yyyy-MM-dd'T'HH:mm:ss") }
        : undefined),
      templateId: id,
    };

    router({
      pathname: RoutesConstant.secure.postShift,
      search: QueryString.stringify({ ...(finalObject as object) }),
    });
  };

  const hasTimeError = useMemo(() => {
    const start = form.getValues('startTime');
    const end = times;

    if (start && end) {
      let newEndTime = DateTime.fromJSDate(end);
      if (start.getTime() > end.getTime()) {
        newEndTime = newEndTime.plus({ days: 1 });
      }
      const hours = getHours(start, newEndTime.toJSDate());

      if (Math.abs(hours.shiftTo('hours').as('hours')) > 14) {
        return true;
      }
    }

    return false;
  }, [times, form]);

  return { form, times, onSubmit, isRecurrent, onAddNewDate, selectDay, hasTimeError };
};

export default CreateShiftTemplatePresenter;
