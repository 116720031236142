import { TFunction } from 'i18next';

export enum DayEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const TIME_ZONE = (t: TFunction) => [
  { id: '1', text: `${t('timeZones.pacific')}`, key: 'America/Los_Angeles', label: 'PT' },
  {
    id: '2',
    text: `${t('timeZones.mountain')}`,
    key: 'America/Denver',
    label: 'MDT',
  },
  {
    id: '3',
    text: `${t('timeZones.central')}`,
    key: 'America/Chicago',
    label: 'CST',
  },
  {
    id: '4',
    text: `${t('timeZones.eastern')}`,
    key: 'America/New_York',
    label: 'ET',
  },
  {
    id: '5',
    text: 'Colombia',
    key: 'America/Bogota',
    label: 'GMT-5',
  },
];

export type TimeZoneType = ReturnType<typeof TIME_ZONE>;

export enum Days {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const AllDays = () => {
  return [
    Days.Monday,
    Days.Tuesday,
    Days.Wednesday,
    Days.Thursday,
    Days.Friday,
    Days.Saturday,
    Days.Sunday,
  ];
};

export const WeekDays = () => {
  return [Days.Monday, Days.Tuesday, Days.Wednesday, Days.Thursday, Days.Friday];
};
