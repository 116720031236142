import ShiftDescriptionPresenter, {
  ShiftDescriptionPresenterType,
} from './ShiftDescription.Presenter';
import { useTranslation } from 'react-i18next';
import Icon from 'assets/icons';
import { createContext } from 'react';
import { Tab } from '@headlessui/react';
import Button from 'components/Button';
import ShiftDescriptionInfo from './ShiftDescriptionInfo/ShiftDescriptionInfo.Screen';
import ShiftDescriptionPros from './ShiftDescriptionPros/ShiftDescriptionPros.Screen';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

export const ShiftDescriptionContext = createContext({} as ShiftDescriptionPresenterType);

const ShiftDescriptionScreen = () => {
  const [t] = useTranslation(['Shifts']);

  const presenter = ShiftDescriptionPresenter();

  const detail = presenter.queryDetailShift.data;

  return (
    <ShiftDescriptionContext.Provider value={presenter}>
      <div className="w-full bg-white">
        <h2 className="font-bold text-2xl text-primary capitalize py-5 px-6 lg:px-10 flex items-center">
          <span className="cursor-pointer" onClick={presenter.onClickBack}>
            <Icon icon="arrowRight" size={14} className="-rotate-180 -mt-1.5 mr-3" />

            <Icon icon={presenter.imageIcon ?? ''} size={24} className="-mt-2 mr-2" />
          </span>
          {detail?.description?.positionTitle}
          {detail?.description?.cancelledWithin24Hours && !detail.description.isActive && (
            <span className="normal-case text-warnings-500 ml-5 text-sm">
              You cancelled this shift within 24 hours
            </span>
          )}
        </h2>

        <hr />

        <Tab.Group>
          <div className="flex justify-between mx-9 items-center">
            <div className="w-2/3">
              <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 px-1 pt-1 max-w-sm">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'w-full py-2.5 text-sm font-medium leading-5',
                      'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white text-secondary-500 border-b border-secondary-500'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-primary text-primary'
                    )
                  }
                >
                  {t('description.shiftInfo')}
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'w-full py-2.5 text-sm font-medium leading-5',
                      'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white text-secondary-500 border-b border-secondary-500'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-primary'
                    )
                  }
                >
                  {t('description.pros')}
                </Tab>
              </Tab.List>
            </div>
            <div className="md:flex flex-wrap hidden">
              <div className="mb-1 md:mt-0">
                {!presenter.queryDetailShift.data?.description?.started &&
                  presenter.queryDetailShift.data?.description?.isActive && (
                    <Button
                      prefixIcon={<Icon icon="delete" size={15} />}
                      size="sm"
                      name="Delete shift"
                      color="danger"
                      onClick={presenter.onClickDelete}
                    />
                  )}
              </div>
              <div className="ml-2">
                <Button
                  prefixIcon={<Icon icon="edit" size={15} />}
                  size="sm"
                  name="Edit shift"
                  color="secondary"
                  disabled={!presenter.queryDetailShift.data?.description?.isActive}
                  onClick={presenter.onClickEdit}
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="md:hidden flex-wrap flex w-full px-10 mt-3">
            <div className="mb-1 md:mt-0">
              {!presenter.queryDetailShift.data?.description?.started &&
                presenter.queryDetailShift.data?.description?.isActive && (
                  <Button
                    prefixIcon={<Icon icon="delete" size={15} />}
                    size="sm"
                    name="Delete shift"
                    color="danger"
                    onClick={presenter.onClickDelete}
                  />
                )}
            </div>
            <div className="ml-2">
              <Button
                prefixIcon={<Icon icon="edit" size={15} />}
                size="sm"
                name="Edit shift"
                color="secondary"
                disabled={!presenter.queryDetailShift.data?.description?.isActive}
                onClick={presenter.onClickEdit}
              />
            </div>
          </div>
          <Tab.Panels className="mt-2 w-full">
            <Tab.Panel
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              <ShiftDescriptionInfo />
            </Tab.Panel>

            <Tab.Panel
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              <ShiftDescriptionPros />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <presenter.Confirmation />
    </ShiftDescriptionContext.Provider>
  );
};

export default ShiftDescriptionScreen;
