import Layout from 'components/Layout';
import Lottie from 'lottie-react';

import PasswordAnimation from '../../assets/lottie/change-password.json';
import PasswordLoading from '../../assets/lottie/loading-password.json';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import InputV2 from 'components/InputV2';
import { RoutesConstant } from 'utils/constants';
import Button from 'components/Button';
import { ActionResetPassword, LoginUser, ResetPassword } from 'actions/auth.api';
import toast from 'react-hot-toast';
import SetupPasswordForm from './common/setup-password-form';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const ForcePasswordPage = () => {
  const { id: email } = useParams();
  const router = useNavigate();
  const { schema } = SetupPasswordForm();
  const [t] = useTranslation(['Forgot']);
  const [loading, setLoading] = useState(false);

  const form = useForm({ resolver: yupResolver(schema) });

  type FormType = yup.InferType<typeof schema>;

  useEffect(() => {
    if (!email) {
      router(-1);
    } else {
      ResetPassword(email).catch(() => toast.error(t('support')));
    }
  }, [email]);

  const onSubmit = (data: FormType) => {
    if (email) {
      setLoading(true);
      ActionResetPassword(
        {
          code: data.code,
          password: data.password,
          confirmPass: data.passwordConfirmation,
        },
        email
      )
        .then(() => {
          LoginUser({
            email,
            password: data.password,
          }).then(() => router('/'));
        })
        .catch(() => {
          setLoading(false);
          toast.error('Invalid code');
        });
    }
  };

  return (
    <Layout>
      <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 flex self-center">
        <div>
          <h1 className="w-full text-4xl font-semibold ">{t('update')}</h1>

          {!loading && (
            <div>
              <p className="mt-3 font-medium">
                {t('codeSent')}: (<span className="font-bold">{email}</span>)
              </p>

              <form onSubmit={form.handleSubmit(onSubmit)}>
                <InputV2
                  register={form.register('code')}
                  placeholder={t('codeEnter')}
                  className="rounded-full mt-4 border-transparent py-2"
                  errors={form.formState.errors.code}
                />

                <InputV2
                  register={form.register('password')}
                  placeholder={t('enterPass')}
                  className="rounded-full mt-4 border-transparent py-2"
                  type="password"
                  errors={form.formState.errors.password}
                />

                <InputV2
                  register={form.register('passwordConfirmation')}
                  placeholder={t('enterNewPass')}
                  className="rounded-full mt-4 border-transparent py-2"
                  type="password"
                  errors={form.formState.errors.passwordConfirmation}
                />

                <Button
                  name={t('changePass')}
                  className="mt-5 w-full flex justify-center"
                  size="md"
                  type="submit"
                />
              </form>
              <div className="flex items-center justify-center mt-4">
                <p className="text-sm font-medium mr-1">{t('shouldNot')}</p>
                <Link
                  to={RoutesConstant.unsecure.login}
                  className="text-secondary-500 py-1 rounded-md text-center text-sm font-semibold underline underline-offset-2"
                >
                  {t('signIn')}
                </Link>
              </div>
            </div>
          )}

          {loading && (
            <div className="self-center w-full">
              <Lottie className="w-full" animationData={PasswordLoading} loop={true} />
            </div>
          )}
        </div>
      </div>

      <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
        <div className="self-center w-full">
          <Lottie className="w-full" animationData={PasswordAnimation} loop={true} />
        </div>
      </div>
    </Layout>
  );
};

export default ForcePasswordPage;
