import { API_ENDPOINTS, getEndpoint } from 'actions/api.endpoints';
import instance from 'actions/http';
import { InvoiceGet } from '../Entities/InvoiceGet.entity';
import { PayInvoice } from '../Dtos/PayInvoice.dto';

export default class InvoiceRepository {
  public async getInvoice(id?: string) {
    const result = await instance.post<InvoiceGet>(
      getEndpoint(API_ENDPOINTS.BUSINESS.GET_INVOICE),
      {
        invoiceId: id,
      }
    );
    return result.data;
  }

  public async payInvoice(data: PayInvoice) {
    return instance.post(getEndpoint(API_ENDPOINTS.PAYMENTS.PAY_INVOICE), data);
  }
}
