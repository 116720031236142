import { EventDataGet } from 'pages/postShift/core/Modules/Catalog/Domain/Entities/Catalog.entity';
import { DraftUseCase } from 'pages/postShift/core/Modules/Checkout/Application/DraftUseCaes';
import { ShiftUseCase } from 'pages/postShift/core/Modules/Checkout/Application/ShiftUseCase';
import { TemplateUseCase } from 'pages/postShift/core/Modules/Checkout/Application/TemplateUseCases';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/hooks/useUser';

const DraftUseCases = new DraftUseCase();
const ShiftUseCases = new ShiftUseCase();
const TemplateUseCases = new TemplateUseCase();

const IndexAction = () => {
  const {
    cognitoUser: { id },
  } = useUser();

  const [t] = useTranslation(['Shifts']);

  const executeGetDraft = async (catalog: EventDataGet | undefined) => {
    const result = await DraftUseCases.invokeGet(id, t, catalog);

    return result;
  };

  const executeGetShiftEdit = async (id: string, catalog: EventDataGet | undefined) => {
    const result = await ShiftUseCases.invokeGetShift(id, t, catalog);

    return result;
  };

  const executeGetTemplate = async (
    id: string,
    catalog: EventDataGet | undefined,
    search: string
  ) => {
    const result = await TemplateUseCases.invokeGetTemplate(id, t, search, catalog);
    return result;
  };

  return {
    executeGetDraft,
    executeGetShiftEdit,
    executeGetTemplate,
  };
};

export default IndexAction;
