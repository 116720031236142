import { useState } from 'react';
import { Role } from 'utils/constants/index';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { DeleteBusinessUserThunk, UpdateRoleBusinessUserThunk } from 'store/business/api.thunks';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { getCognitoUser } from 'store/business/selectors';
import SelectV2 from 'components/SelectV2';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'assets/icons';

interface ICollaborator {
  id: string;
  name: string;
  position: string;
  since: string;
  permitions: string;
  roleNumber: number;
  email: string;
}

const Collaborator = ({ id, name, permitions, roleNumber, email }: ICollaborator): JSX.Element => {
  const roles: IItems[] = Role.map((value, key) => {
    return { id: key.toString(), text: value.toString() };
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const business = useSelector(getCognitoUser);

  const dispatch = useAppDispatch();

  const [t] = useTranslation(['Settings']);

  const [role, setRole] = useState({ id: roleNumber.toString(), text: permitions });

  const onChangeRole = async (role: number) => {
    try {
      setLoading(true);
      await dispatch(UpdateRoleBusinessUserThunk({ id, role })).unwrap();
      toast.success(t('success'));
    } finally {
      setLoading(false);
    }
  };

  const onDelete = () => {
    dispatch(DeleteBusinessUserThunk(id))
      .unwrap()
      .then(() => {
        toast.success(t('success'));
        setModalOpen(false);
      });
  };

  return (
    <div className="text-primary border flex flex-nowrap mb-3 w-full rounded-xl p-3 border-neutralGray justify-between bg-white">
      <div className="flex items-center w-full">
        <div className="rounded-full bg-secondary-500 w-[46px] h-[46px] flex justify-center items-center mr-1 text-white ">
          {name.charAt(0).toUpperCase()}
        </div>
        <div className="flex flex-col ml-5">
          <p className="text-lg font-bold">{name}</p>
          <p className=" text-xs">{email}</p>
        </div>
      </div>

      <div className=" w-full flex items-center justify-end">
        {business?.role === 0 ? (
          <div className="">
            <SelectV2
              itemSelected={role}
              items={roles}
              name="Select"
              disabled={loading || business?.id === id}
              setSelectedItem={(val) => {
                setRole(val);
                onChangeRole(parseInt(role.id));
              }}
            />
          </div>
        ) : (
          <p className="text-sm lg:block hidden">{permitions}</p>
        )}
      </div>

      {business?.id !== id && (
        <div
          className="relative right-0 cursor-pointer flex items-center"
          onClick={() => setModalOpen(true)}
        >
          <Icon icon="delete" size={25} className="text-red" />
        </div>
      )}

      <Modal
        customComponent={
          <div className=" w-[400px] max-h-[200px]">
            <div className="text-primary px-5 ">
              <div className=" py-2">
                <h3 className="text-2xl text-red font-bold">{t('delete')}</h3>
                <p className="text-sm font-thin text-red mt-3 mb-4">{t('memberDelete')}</p>

                <br />

                <Button
                  onClick={onDelete}
                  style={{ width: '100%' }}
                  type="button"
                  color="danger"
                  name={t('delete')}
                />

                <Button
                  onClick={() => setModalOpen(false)}
                  style={{ width: '100%', marginTop: 10 }}
                  type="button"
                  color="outline"
                  name={t('cancel')}
                />
              </div>
            </div>
          </div>
        }
        actionClose={() => setModalOpen(false)}
        modalOpen={modalOpen}
      />
    </div>
  );
};

export default Collaborator;
