import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Card from 'components/Card/styles';
import { IOnBoardingApiRequest, TaxTypeEnum } from 'actions/business/model';
import { useSelector } from 'react-redux';
import { getCompanyReferred } from 'store/business/selectors';

interface IW2Select {
  setData: (data: IOnBoardingApiRequest) => void;
  data?: IOnBoardingApiRequest;
  onSubmit: () => void;
}

const W2Select = ({ setData, data, onSubmit }: IW2Select) => {
  const [t] = useTranslation(['OnBoarding']);
  const [selected, setSelected] = useState<TaxTypeEnum>(TaxTypeEnum.F_1099);
  const { Title, SubTitle, Description } = Card();
  const companyReferred = useSelector(getCompanyReferred);

  useEffect(() => {
    if (data) {
      setData({ ...data, taxType: selected });
    }
  }, [selected]);

  return (
    <div>
      <h2 className="text-xl sm:text-2xl text-left font-bold text-primary">
        {t('onboarding.mainTitleW2')}
      </h2>
      <div className="mt-5">
        <div
          onClick={() => setSelected(1)}
          className={Card({ selected: selected === TaxTypeEnum.F_1099 }).base()}
        >
          <h3 className={Title()}>{t('onboarding.title')}</h3>{' '}
          {/** When you need to add a new Company refer, we must create a util function for this case: */}
          <h4 className={SubTitle()}>
            <span style={{ textDecoration: companyReferred ? 'line-through' : '' }}>30%</span>
            {companyReferred && <span className="mx-1.5">20%</span>}
            {t('onboarding.mark')}
          </h4>
          <h6 className={Description()}>{t('onboarding.description')}</h6>
        </div>
      </div>

      {!companyReferred && (
        <div className="mt-5">
          <div onClick={() => setSelected(2)} className={Card({ selected: selected === 2 }).base()}>
            <h3 className={Title()}>{t('onboarding.titleW2')}</h3>

            <h4 className={SubTitle()}> 42% {t('onboarding.mark')}</h4>
            <h6 className={Description()}>{t('onboarding.descriptionW2')}</h6>
          </div>
        </div>
      )}

      <div className="text-center mt-8">
        <Button
          name={t('signUp')}
          color="primary"
          size="md"
          onClick={onSubmit}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default W2Select;
