import Input from 'components/InputPost';
import { SubmitHandler, useForm } from 'react-hook-form';
import { emailRegex, passwordRegex, RoutesConstant } from 'utils/constants';
import { useEffect, useState } from 'react';
import { CloseSession, IRegisterUser, LoginUser, RegisterUser } from 'actions/auth.api';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { REACT_APP_CDN } from 'utils/enviroment';
import Federated from 'components/Federated';
import PasswordInput from 'components/PasswordInput';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { GetBusinessThunk, GetBusinessUserIdThunk } from 'store/business/api.thunks';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { setEmailRecovery, setPassword } from 'store/business';

const RegisterScreen = (): JSX.Element => {
  const [checked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [t] = useTranslation(['Register']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();
  const [search] = useSearchParams();

  const partner = search.get('partner');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IRegisterUser>({ criteriaMode: 'all', mode: 'onChange', reValidateMode: 'onChange' });

  useEffect(() => {
    checked && setError(false);
  }, [checked]);

  const onSubmit: SubmitHandler<IRegisterUser> = (data) => {
    setLoading(true);
    RegisterUser(data, partner)
      .then((user) => {
        if (!user.confirmed) {
          dispatch(setEmailRecovery(data.email));
          dispatch(setPassword(data.password));
          return LoginUser({ email: data.email, password: data.password }).then((result) => {
            if (result) {
              sessionStorage.clear();
              dispatch(GetBusinessUserIdThunk(result.getIdToken().payload.sub))
                .unwrap()
                .then((result) => {
                  if (!result.isActive) {
                    toast.error(t('notFound'));
                    CloseSession();
                  } else if (result.businessId) {
                    dispatch(GetBusinessThunk(result.businessId));
                    navigate(RoutesConstant.secure.shift);
                  } else {
                    navigate(RoutesConstant.secure.onBoarding);
                  }
                });
            } else {
              navigate(RoutesConstant.unsecure.login);
            }
          });
        }
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        toast.success(`${t('success')}`);
        setLoading(false);
      });
  };

  const password = watch('password');

  useEffect(() => {
    trackPageView({
      documentTitle: 'Sign Up',
      href: window.location.href,
    });
  }, []);

  return (
    <div className="flex h-[100vh] bg-[#E5E5E5] w-full">
      <div className="w-full lg:w-1/2 p-10 sm:p-20 xl:p-40 flex self-center">
        <div className="w-full">
          <h2 className="text-3xl font-bold pb-6 text-primary">{t('create')}</h2>
          <Federated />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-4 mb-5 flex items-center justify-between text-primary">
              <p className="text-lg text-primary">{t('or')}</p>
            </div>
            <div className="my-2 h-1" />
            <Input
              customStyle="mt-4 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              label={'name'}
              name="name"
              placeholder={t('name')}
              register={register}
              type="text"
              errors={errors.name}
              validation={{
                required: true,
              }}
            />
            <Input
              customStyle="mt-5 font-medium text-sm sm:text-base appearance-none rounded-2xl relative block w-full px-4 py-2 border border-[#EDEDED] bg-white placeholder-primary text-gray-900  focus:outline-secondary-500 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              label={'email'}
              name="email"
              placeholder={t('email')}
              register={register}
              type="email"
              errors={errors.email}
              validation={{
                required: true,
                pattern: emailRegex,
              }}
            />
            <div className="my-2"></div>
            <PasswordInput
              label={'password'}
              name="password"
              placeholder={t('password')}
              register={register}
              type="password"
              errors={errors.password}
              validation={{
                required: true,
                pattern: { value: passwordRegex, message: t<string>('passwordRequirements') },
              }}
            />
            <div className="my-2"></div>
            <PasswordInput
              label={'confirmPassword'}
              name="repeat_password"
              placeholder={t('confirmPass')}
              register={register}
              type="password"
              errors={errors.repeat_password}
              validation={{
                required: true,
                pattern: { value: passwordRegex, message: t<string>('passwordRequirements') },
                validate: (value: string) => value === password || `${t('noMatch')}`,
              }}
            />

            <small className="text-red text-left mt-1">{error && `${t('accept')}`}</small>
            <div className="flex justify-center my-5">
              <button
                disabled={loading}
                className="w-[100%] rounded-full py-2  font-semibold bg-primary text-white "
              >
                {t('sign')}
              </button>
            </div>

            <div className="flex items-center justify-center ">
              <p className="text-sm font-semibold mr-1">{t('haveAcc')} </p>
              <Link
                to={RoutesConstant.unsecure.login}
                className="text-[#117AF4] py-1 rounded-md text-center text-sm font-semibold underline underline-offset-2"
              >
                {t('login')}
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 bg-lightBlue/30 hidden lg:flex justify-center">
        <div className="self-center ">
          <img
            src={`${REACT_APP_CDN}/auth-icon.svg`}
            alt={`${REACT_APP_CDN}/auth-icon.svg`}
            className="pl-10"
          />
          <div className="w-full text-center mt-5">
            <h3 className="font-bold text-primary text-xl">{t('chambaProv')}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
