import { useContext, useEffect, useMemo, useState } from 'react';
import CheckoutInformationAction from './CheckoutInformation.Action';
import { TemplateHubContext } from 'pages/postShift/core/Infrastructure/Providers/TemplateHub.provider';
import { useNavigate } from 'react-router-dom';
import {
  DataFormContext,
  DataFormType,
} from 'pages/postShift/core/Infrastructure/Providers/DataForm.provider';
import useUser from 'utils/hooks/useUser';
import { Country } from 'actions/business/model';
import { PostDraftDto } from 'pages/postShift/core/Modules/Checkout/Domain/Dtos/PostDraft.dto';
import { useMutation } from '@tanstack/react-query';
import BusinessService from 'actions/business';
import toast from 'react-hot-toast';
import { RoutesConstant } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { setMenu } from 'store/generalSlice';

const CheckoutInformationPresenter = () => {
  const action = CheckoutInformationAction();
  const [t] = useTranslation(['Shifts']);
  const dispatch = useAppDispatch();

  const {
    business: { country },
  } = useUser();

  const ctxHub = useContext(TemplateHubContext);
  const form = useContext(DataFormContext);
  const [openPayment, setOpenPayment] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [templateName, setTemplateName] = useState('');

  const idDraft = ctxHub?.draft?.resultDraftData.id;

  const navigate = useNavigate();

  const onClickDelete = async () => {
    if (ctxHub?.draft?.resultDraftData.id) {
      await action.invokeDeleteDraft(ctxHub?.draft?.resultDraftData.id);
    }
    navigate(0);
  };

  useEffect(() => {
    if (openPayment) {
      dispatch(setMenu(false));
    }
  }, [openPayment]);

  const onOpenConfirmation = () => {
    setOpenConfirmation(true);
  };
  const onCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const total = useMemo(() => {
    return action.invokeCalcSummary(ctxHub?.draft);
  }, [ctxHub?.draft]);

  // TODO: REMOVE THIS AFTER PAYMENT DEFINED

  const { mutateAsync, isLoading } = useMutation(BusinessService.postEvent);

  const onCheckout = () => {
    if (country == Country.Colombia) {
      if (ctxHub?.draft) {
        const postEvent: PostDraftDto = {
          id: ctxHub.draft.resultDraftData.id,
          paymentTotal: action.invokeCalcSummary(ctxHub.draft).total ?? 0,
          shifts: ctxHub?.draft?.resultDraftData.shifts.map((v) => ({
            id: v.id,
            openings: v.openings,
          })),
        };

        mutateAsync(postEvent).then(() => {
          toast.success(t('create.success'));
          form.setDataForm({});
          navigate(RoutesConstant.secure.shift);
        });
      }
    } else {
      setOpenPayment(true);
    }
  };

  const onCreateTemplate = () => {
    action.invokeCreateTemplate(form.dataForm as DataFormType, templateName).then(() => {
      if (form.setTemplateCreated) form.setTemplateCreated(true);
    });
  };

  return {
    onClickDelete,
    total,
    setOpenPayment,
    openPayment,
    form,
    idDraft,
    openConfirmation,
    setOpenConfirmation,
    onOpenConfirmation,
    onCloseConfirmation,
    onCheckout,
    isLoading,
    setTemplateName,
    templateName,
    onCreateTemplate,
  };
};

export default CheckoutInformationPresenter;
