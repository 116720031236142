import InvoiceUseCases from '../core/Modules/Invoice/Application/InvoiceUseCase';

const invoiceUseCase = new InvoiceUseCases();

const IndexInvoiceDetailAction = () => {
  const executeGetInvoice = (id?: string) => {
    return invoiceUseCase.invokeGetInvoice(id);
  };
  return { executeGetInvoice };
};

export default IndexInvoiceDetailAction;
