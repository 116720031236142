import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export const parseException = (e: unknown) => {
  if (e instanceof AxiosError) {
    toast.error(e.response?.data?.message ?? 'Something went wrong');
  } else {
    toast.error('Something went wrong');
  }
};
