import { createContext, useContext, useEffect, useState } from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { ValidationsFormUseCase } from '../../Modules/Checkout/Application/ValidationsFormUseCase';
import { DataFormContext } from './DataForm.provider';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

type Steps = 'shiftInfo' | 'worker' | 'attire' | 'confirm';
enum StepNumber {
  'shiftInfo' = 1,
  'worker' = 2,
  'attire' = 3,
  'confirm' = 4,
}

type StepperProps = {
  changeStep: (s: Steps, byPassStep?: boolean) => void;
  selectedStep: string;
  firstEntry: boolean;
  setFirstEntry: (s: boolean) => void;
} & Partial<StepWizardChildProps>;

export const StepperContext = createContext({} as StepperProps);

const StepperProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const validationForm = ValidationsFormUseCase();
  const [t] = useTranslation(['Shifts']);
  const [instance, setInstance] = useState<Partial<StepWizardChildProps>>();
  const [selectedStep, setSelectedStep] = useState('shiftInfo');
  const ctxForm = useContext(DataFormContext);
  const [firstEntry, setFirstEntry] = useState(false);

  const changeStep = async (name: Steps, byPassStep?: boolean) => {
    let readyToMove = false;

    if (ctxForm.mode === 'draft') {
      return toast.error(t('draftDone'));
    }

    switch (instance?.currentStep) {
      case StepNumber.shiftInfo:
        readyToMove = await validationForm.validateShiftInfo(ctxForm.dataForm);
        break;

      case StepNumber.worker:
        readyToMove = await validationForm.validateWorker(ctxForm.dataForm);
        break;

      case StepNumber.attire:
        readyToMove = await validationForm.validateAttire(ctxForm.dataForm);
        break;

      default:
        break;
    }
    if ((instance?.currentStep ?? 1) > StepNumber[name]) {
      readyToMove = true;
    }

    if (byPassStep) {
      readyToMove = true;
    }
    if (!readyToMove) {
      return toast.error(t('error'));
    }

    if (instance && instance.goToNamedStep) {
      instance.goToNamedStep(name);
      setSelectedStep(name);
    }
  };

  useEffect(() => {
    if (ctxForm.mode === 'draft' && instance?.goToNamedStep) {
      setSelectedStep('confirm');
      instance.goToNamedStep('confirm');
    }
  }, [ctxForm.mode, instance]);

  return (
    <StepperContext.Provider
      value={{ ...instance, changeStep, selectedStep, setFirstEntry, firstEntry }}
    >
      <div className="px-4 md:px-16 pt-4 flex z-10 text-lg">
        <div className="w-1/4 md:w-1/5" onClick={() => changeStep('shiftInfo')}>
          <p
            className={`pb-4 max-w-max z-10  ${
              selectedStep === 'shiftInfo'
                ? 'border-b border-secondary-500 text-secondary-500'
                : 'text-neutral-600'
            }`}
          >
            {t('description.shiftInfo')}
          </p>
        </div>
        <div className=" border-x border-neutral-400 border-opacity-50 max-h-min mb-4 border-1.5 rounded-full" />
        <div className="w-1/4 md:w-1/5" onClick={() => changeStep('worker')}>
          <p
            className={`pb-4  z-10  max-w-max m-auto ${
              selectedStep === 'worker'
                ? 'border-b border-secondary-500 text-secondary-500'
                : 'text-neutral-600'
            }`}
          >
            {t('description.worker')}
          </p>
        </div>
        <div className=" border-x border-neutral-400 border-opacity-50 max-h-min mb-4 border-1.5 rounded-full" />
        <div className="w-1/4 md:w-1/5" onClick={() => changeStep('attire')}>
          <p
            className={`pb-4  z-10  max-w-max m-auto ${
              selectedStep === 'attire'
                ? 'border-b border-secondary-500 text-secondary-500'
                : 'text-neutral-600'
            }`}
          >
            {t('create.confirmation.attire')}
          </p>
        </div>
        <div className=" border-x border-neutral-400 border-opacity-50 max-h-min mb-4 border-1.5 rounded-full" />
        <div className="w-1/4 md:w-1/5" onClick={() => changeStep('confirm')}>
          <p
            className={`pb-4  z-10 max-w-max m-auto ${
              selectedStep === 'confirm'
                ? 'border-b border-secondary-500 text-secondary-500'
                : 'text-neutral-600'
            }`}
          >
            {t('create.confirm.continue')}
          </p>
        </div>
      </div>

      <hr className="text-neutral-600 w-full translate-y-[-1px] z-0" />
      <StepWizard instance={setInstance} isLazyMount className="px-4 md:px-16">
        {children}
      </StepWizard>
    </StepperContext.Provider>
  );
};

export default StepperProvider;
