import { UseQueryResult, useQuery } from '@tanstack/react-query';
import ShiftDescriptionAction from './ShiftDescription.Action';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesConstant, carouselData } from 'utils/constants';
import { ShiftDescriptionGet } from 'pages/shift/core/Modules/Shifts/Domain/Entities/ShiftDedscription.entity';
import useConfirm from 'utils/confirmation';
import { useTranslation } from 'react-i18next';

export interface ShiftDescriptionPresenterType {
  queryDetailShift: UseQueryResult<ShiftDescriptionGet | undefined, unknown>;
  imageIcon: string | undefined;
  onClickBack: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
  Confirmation: () => JSX.Element;
}

const shiftDescriptionAction = ShiftDescriptionAction();

const ShiftDescriptionPresenter = (): ShiftDescriptionPresenterType => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getConfirmation, Confirmation } = useConfirm();
  const [t] = useTranslation(['Shifts']);

  const queryDetailShift = useQuery({
    queryFn: () => shiftDescriptionAction.executeGetShiftDescription(id ?? ''),
  });

  const imageIcon = carouselData.find(
    (car) => car.id === queryDetailShift.data?.description?.positionId
  )?.image;

  const onClickBack = () => navigate(-1);

  const onClickEdit = () => {
    navigate(`${RoutesConstant.secure.postShift}?id=${id}`);
  };

  const onClickDelete = async () => {
    const data = queryDetailShift.data?.description;

    const is24h = shiftDescriptionAction.executeIs24Cancel(t, data?.timeZone, data?.startDate);
    let text = 'Do you confirm to delete this shift?';
    if (is24h) {
      text =
        'You are deleting a shift within 24 hours of the start time, you may get charged for 4 hours for those who already accepted this shift. Do you confirm?';
    }

    const conf = await getConfirmation(text);

    if (conf) {
      shiftDescriptionAction.executeDeleteShift(id ?? '').then(() => {
        queryDetailShift.refetch();
      });
    }
  };

  return {
    queryDetailShift,
    imageIcon,
    onClickBack,
    onClickEdit,
    onClickDelete,
    Confirmation,
  };
};

export default ShiftDescriptionPresenter;
