import './App.css';
import { AWSInitialConfiguration } from 'utils/cognito/index';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { CheckCognitoInstance, CheckLoginMethod } from 'actions/auth.api';
import { useAppDispatch } from 'store';
import { setLoading, setLoggedIn } from 'store/business';
import {
  GetBusinessThunk,
  GetBusinessUserIdThunk,
  RegisterFederatedThunk,
} from 'store/business/api.thunks';
import RoutesIndex from 'routes';
import { useNavigate } from 'react-router-dom';
import { RoutesConstant } from 'utils/constants';
import { AuthMethod } from 'utils';
import { REACT_APP_SRIPE_KEY } from 'utils/enviroment';
import { Helmet, HelmetProvider } from 'react-helmet-async'; 

AWSInitialConfiguration();

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const stripePromise = loadStripe(REACT_APP_SRIPE_KEY ?? '', { locale: 'auto' });

  useEffect(() => {
    CheckCognitoInstance().then((result) => {
      dispatch(setLoggedIn(result != null));
      if (result) {
        const idToken = result.getIdToken();
        const authMethod = CheckLoginMethod(result);
        if (authMethod !== AuthMethod.Email) {
          dispatch(
            RegisterFederatedThunk({
              authMethod,
              cognitoId: idToken.payload.sub,
              email: idToken.payload.email ?? '',
              name: idToken.payload.name ?? 'Default user',
            })
          )
            .unwrap()
            .then((val) => {
              if (val.businessId) {
                dispatch(GetBusinessThunk(val.businessId));
              } else {
                navigate(RoutesConstant.secure.onBoarding, { replace: true });
                dispatch(setLoading(false));
              }
            })
            .catch(() => {
              navigate(RoutesConstant.secure.onBoarding, { replace: true });
            });
        } else {
          dispatch(GetBusinessUserIdThunk(result.getIdToken().payload.sub))
            .unwrap()
            .then((result) => {
              if (result.businessId) {
                dispatch(GetBusinessThunk(result.businessId));
              } else {
                navigate(RoutesConstant.secure.onBoarding, { replace: true });
                dispatch(setLoading(false));
              }
            });
        }
      } else {
        dispatch(setLoading(false));
      }
    });
  }, [dispatch, navigate]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="Staff On-Demand" />
          <meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
          <meta name="twitter:image" content="%PUBLIC_URL%/logo512.png" />
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        </Helmet>
        <Elements
          stripe={stripePromise}
          options={{
            appearance: {
              theme: 'stripe',
            },
          }}
        >
          <RoutesIndex />
          <Toaster toastOptions={{ position: 'top-right', duration: 4000 }} />
        </Elements>
      </HelmetProvider>
    </>
  );
};

export default App;
