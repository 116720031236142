import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileCard from '../ProfileCard';
import ProfileInformation from '../ProfileInformation';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowDimensions from 'utils/hooks/window';
import { useGetPros, useMutationPros } from 'pages/professionals/helper';
import { Menu } from '@headlessui/react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

type BrowseProps = {
  setIdHire: React.Dispatch<React.SetStateAction<{ name: string; id: string } | undefined>>;
};

const Browse = ({ setIdHire }: BrowseProps): JSX.Element => {
  const [t] = useTranslation('ApplicantsProfile');
  const [openProfileModal, setOpenProfileModal] = React.useState<boolean>(false);
  const { height } = useWindowDimensions();
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);

  const openProfileModalHandler = (userId: string) => {
    setOpenProfileModal(true);
    setSelectedUserId(userId);
  };

  const { generalList, onEndReached, items, refreshList } = useGetPros('general');
  const { addPreferredUser, blockUser } = useMutationPros();

  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Professionals',
      href: '/browse',
    });
  }, []);

  return (
    <div>
      <div id="scrollable">
        <hr className="mt-5 w-full border-[1px] rounded-full  border-grayText" />
        <div className="mt-2 pb-2">
          {items.length > 0 ? (
            <InfiniteScroll
              dataLength={items.length}
              next={onEndReached}
              hasMore={items.length < generalList.totalItems}
              className={'pb-4'}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollable"
              height={height * 0.7}
            >
              {items.map((jobseeker) => (
                <ProfileCard
                  key={jobseeker.userId}
                  name={jobseeker.name}
                  totalShifts={jobseeker.totalShifts}
                  userImage={jobseeker.userImage}
                  userId={jobseeker.userId}
                  preferredUserId={jobseeker.preferredUserId}
                  openProfile={() => {
                    openProfileModalHandler(jobseeker.userId);
                  }}
                  rating={jobseeker.rating}
                  ratesNumber={jobseeker.ratesNumber}
                  onRefresh={refreshList}
                  menuItems={
                    <>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-primary text-white' : 'text-primary'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() => addPreferredUser(jobseeker.userId)}
                          >
                            {t('actions.addFavorite')}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-primary text-white' : 'text-primary'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() => blockUser(jobseeker.userId)}
                          >
                            {t('actions.block')}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-primary text-white' : 'text-primary'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={() =>
                              setIdHire({ id: jobseeker.userId, name: jobseeker.name })
                            }
                          >
                            {t('actions.hire')}
                          </button>
                        )}
                      </Menu.Item>
                    </>
                  }
                />
              ))}
            </InfiniteScroll>
          ) : (
            <div className="flex justify-center items-center h-96">
              <h3 className="text-2xl font-bold text-gray-500">{t('emptyGeneralList')}</h3>
            </div>
          )}
        </div>
      </div>
      <ProfileInformation
        isOpen={openProfileModal}
        closeModal={() => {
          setOpenProfileModal(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
    </div>
  );
};

export default Browse;
