import { useState } from 'react';
import ModalPolicy from './ModalPolicy';
import ModalForm from './ModalForm';

type HireProps = {
  userId: string;
  userName: string;
  setHire: (s?: { name: string; id: string }) => void;
};

const Hire = ({ userId, userName, setHire }: HireProps) => {
  const [openModalPolicy, setOpenModalPolicy] = useState(true);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [hireAmount, setHireAmount] = useState(0);

  const onClickAcceptPolicy = (hireAmnt: number) => {
    setOpenModalPolicy(false);
    setOpenModalForm(true);
    setHireAmount(hireAmnt);
  };

  const onOfferHire = () => {
    setOpenModalForm(false);
    setOpenModalPolicy(false);
    setHire(undefined);
  };

  return (
    <div>
      <ModalForm
        userId={userId}
        open={openModalForm}
        setOpen={setOpenModalForm}
        hireAmount={hireAmount}
        setHire={setHire}
        onOfferHire={onOfferHire}
      />
      <ModalPolicy
        userName={userName}
        userId={userId}
        open={openModalPolicy}
        setOpen={setOpenModalPolicy}
        onClickAccept={onClickAcceptPolicy}
        setHire={setHire}
      />
    </div>
  );
};

export default Hire;
