import { tv } from 'tailwind-variants';

export const ButtonStyle = tv({
  base: 'font-medium bg-blue-500 text-white rounded-full active:opacity-80 text-center flex justify-center',
  variants: {
    color: {
      primary: 'bg-primary text-white',
      secondary: 'text-white bg-secondary-500',
      danger: 'bg-warnings-400 text-white',
      outline: 'border border-primary bg-white text-primary',
      ghost: 'text-primary bg-transparent',
      outlineSecondary: 'border-[1px] border-secondary-500 bg-transparent text-secondary-500',
    },
    rounded: {
      xs: 'rounded-sm py-1',
      sm: 'rounded',
      md: 'rounded-md',
      lg: 'rounded-lg',
      full: 'rounded-full',
    },
    size: {
      xs: 'text-xs px-4 py-1',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'px-4 py-3 text-lg',
    },
  },
  compoundVariants: [
    {
      size: ['sm', 'md'],
      class: 'sm:px-6 px-2 py-1',
    },
  ],
  defaultVariants: {
    size: 'md',
    color: 'primary',
  },
});
