import { array, boolean, date, lazy, number, object, string, InferType } from 'yup';

export const ShiftInfoFormSchema = object({
  recurrent: boolean().required(),
  startDate: lazy((value) => {
    if (Array.isArray(value)) {
      return array()
        .of(date().required())
        .when('recurrent', {
          is: (recurrent: boolean) => recurrent === false,
          then: (schema) => schema.required(),
        });
    }
    return date().when('recurrent', {
      is: (recurrent: boolean) => recurrent === false,
      then: (schema) => schema.required(),
    });
  }),
  recurrentStart: date().when('recurrent', {
    is: (recurrent: boolean) => recurrent === true,
    then: (schema) => schema.required(),
  }),

  recurrentEnd: date().when('recurrent', {
    is: (recurrent: boolean) => recurrent === true,
    then: (schema) => schema.required(),
  }),

  recurrentDays: array(number().required()).when('recurrent', {
    is: (recurrent: boolean) => recurrent === true,
    then: (schema) => schema.required(),
  }),

  startTime: date().required(),
  endTime: date().required(),
  timeZone: object({
    id: string().required(),
    text: string().required(),
  }).required(),
});

export type ShiftInfoFormType = InferType<typeof ShiftInfoFormSchema>;
