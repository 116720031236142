import { InputHTMLAttributes, forwardRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface InputV2Props extends InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: JSX.Element;
  errors?: FieldError;
  register?: UseFormRegisterReturn; // Para recibir register de Hook Form
}

const InputV2 = forwardRef<HTMLInputElement, InputV2Props>(
  ({ prefixIcon, className, errors, register, ...props }, propRef) => {
    const [t] = useTranslation(['Others']);

    return (
      <>
        <div className="flex relative">
          {prefixIcon && <div className="absolute pl-3 pt-1.5">{prefixIcon}</div>}
          <input
            ref={propRef}
            className={twMerge(
              'border-[1px] border-neutral-400 focus:outline-secondary-400 text-neutral-1600 placeholder:text-neutral-1600 text-base rounded py-1.5 w-full',
              className,
              prefixIcon ? 'pl-7' : 'px-3 '
            )}
            {...props}
            {...register}
          />
        </div>
        <small className="text-warnings-400 text-left mt-1">
          {errors && (errors.message ?? t('invalid'))}
        </small>
      </>
    );
  }
);

InputV2.displayName = 'InputV2';

export default InputV2;
