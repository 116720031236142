import * as React from 'react';
import { ButtonStyle } from './Button.styles';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name?: string;
  color?: 'primary' | 'secondary' | 'danger' | 'outline' | 'ghost' | 'outlineSecondary';
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined;
  rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'full';
  prefixIcon?: JSX.Element;
  children?: React.ReactNode;
  loading?: boolean;
}

const Button = ({
  name,
  color = 'primary',
  size,
  rounded,
  className,
  prefixIcon,
  children,
  loading,
  ...rest
}: ButtonProps): JSX.Element => {
  return (
    <button {...rest} className={twMerge(ButtonStyle({ color, size, rounded }), 'flex', className)}>
      {loading ? (
        <div
          className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      ) : (
        <>
          {prefixIcon && <div className="mr-2">{prefixIcon}</div>}
          {name ?? children}
        </>
      )}
    </button>
  );
};

export default Button;
