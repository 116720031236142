import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import IndexInvoiceAction from './Index.Action';
import { RoutesConstant } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

const IndexInvoicePresenter = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const actions = IndexInvoiceAction();

  const getInvoices = useQuery(['get-invoices-by-business', page], () =>
    actions.executeGetInvoice(page)
  );

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const onClickExport = (id: string) => {
    navigate(`${RoutesConstant.unsecure.invoiceDetail}/${id}`);
  };
  return { getInvoices, page, onClickExport, handlePageClick };
};

export default IndexInvoicePresenter;
